import * as yup from "yup";

const isEmailValid = (email: string): boolean => {
  if (email === "") {
    return true;
  }

  return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);
};
export default isEmailValid;

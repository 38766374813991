import colors from "./colors";

const shadows = {
  outline: `0px 0px 0px 1px ${colors["dpd-black"]}`,
  "inset-outline": `inset 0px 0px 0px 1px ${colors["dpd-black"]}`,
  error: `0px 0px 4px ${colors.red.lead}`,
  dropdown: `0px 1px 4px ${colors.shadow}`,
  menu: "-4px 1px 6px 3px rgba(186, 186, 186, 0.25)",
};
export default shadows;

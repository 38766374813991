import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const TextInput: ComponentMultiStyleConfig = {
  parts: ["inputContainer", "inputContent", "input", "textarea", "leftAddon", "rightAddon", "label"],
  baseStyle: {
    inputContainer: {
      background: "white",
      border: "1px",
      borderColor: "grey.mid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      _focusWithin: {
        boxShadow: "outline",
      },
    },
    inputContent: {
      flexGrow: 1,
      height: "100%",
      position: "relative",
      zIndex: 0,
    },
    input: {
      height: "100%",
      fontSize: responsiveStyle({
        mobile: "1rem",
        desktop: "0.875rem",
      }),
      color: "dpd-black",
      borderRadius: 0,
      px: "1rem",
      zIndex: 0,
      _placeholder: {
        fontSize: "0.875rem",
        color: "grey.mid",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
    textarea: {
      overflow: "hidden",
      resize: "none",
      flex: 1,
    },
    leftAddon: {
      position: "absolute",
      left: "1rem",
      zIndex: 10,
    },
    rightAddon: {
      position: "absolute",
      right: "1rem",
    },
    label: {
      position: "absolute",
      top: "0.25rem",
      zIndex: 10,
      left: "1rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
};
export default TextInput;

import * as yup from "yup";

import { TAuthData } from "../types";

const schema: yup.Schema<TAuthData> = yup
  .object()
  .shape({
    token: yup.string().nullable(),
    refreshToken: yup.string().nullable(),
    isPasswordExpired: yup.boolean().nullable(),
    hasInvalidDefaultAddresses: yup.boolean().nullable(),
  })
  .required();

const validateAuthDataResponse = (responseData: any): TAuthData => {
  const { token, refreshToken, isPasswordExpired, hasInvalidDefaultAddresses } = schema.validateSync(responseData);
  if (!token || !refreshToken) {
    throw new Error("AuthData does not contain token or refreshToken");
  }
  return { token, refreshToken, isPasswordExpired, hasInvalidDefaultAddresses };
};

export default validateAuthDataResponse;

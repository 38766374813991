export const LANDING = "/";
export const INTERNAL_ERROR = "/internal-error";
export const NOT_FOUND = "/not-found";
export const FALLBACK = "/fallback";
export const FAQ = "/faq";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/password/reset";
export const PROFILE = "/profile";
export const USER_DATA = "/data";
export const USER_DATA_ADDRESS_BOOK = "/data/address-book";
export const USER_DATA_INVOICES = "/data/invoices";
export const PROFILE_MY_ADDRESSES = "/profile/my-addresses";
export const ORDER = "/order";
export const PROFILE_ACCOUNT = "/profile/account";
export const ORDER_SHIPMENT = "/order/shipment";
export const ORDER_SENDER = "/order/sender";
export const ORDER_RECEIVER = "/order/receiver";
export const ORDER_SUMMARY = "/order/summary";
export const ORDER_SUCCESS = "/order/success";
export const SHIPMENTS = "/data/shipments";
export const NOT_SUPPORTED = "/not-supported";
export const PAYMENT = "/payment";
export const RDL_RESULT = "/order/paymentByOrderId/result";

import NextRouter, { SingletonRouter } from "next/router";

import { getLocale } from "./utils/getLocale";

class Router {
  nextRouter: SingletonRouter;

  constructor() {
    this.nextRouter = NextRouter;
  }

  get locale(): string | undefined {
    if (typeof window === "undefined") {
      return undefined;
    }

    const requestLocale = this.nextRouter.locale;
    const { locale } = getLocale(window.location.host, requestLocale);

    return locale;
  }

  get localeList(): string[] | undefined {
    if (typeof window === "undefined") {
      return undefined;
    }

    const requestLocale = this.nextRouter.locale;
    const { locales } = getLocale(window.location.host, requestLocale);

    return locales;
  }

  get defaultLocale(): string | undefined {
    if (typeof window === "undefined") {
      return undefined;
    }

    const requestLocale = this.nextRouter.locale;
    const { locales } = getLocale(window.location.host, requestLocale);

    return locales?.[0];
  }
}
export default new Router();

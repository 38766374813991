import { useEffect } from "react";

import { useRouter } from "next/router";

import { ROUTES } from "@utils/constants";

import useOrderStorage from "./storage/useOrderStorage";

// Clears stored order data if current page is not /order or /payment

const orderPathnames = [
  ROUTES.ORDER,
  ROUTES.ORDER_SHIPMENT,
  ROUTES.ORDER_SENDER,
  ROUTES.ORDER_RECEIVER,
  ROUTES.ORDER_SUMMARY,
  ROUTES.ORDER_SUCCESS,
  ROUTES.PAYMENT,
];

const useResetStoredOrder = (): void => {
  const router = useRouter();

  const { clearData } = useOrderStorage();

  useEffect(() => {
    if (orderPathnames.includes(router.pathname)) {
      return;
    }
    clearData();
  }, [clearData, router.pathname]);
};
export default useResetStoredOrder;

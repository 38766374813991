type TNullish<T> = T | null | undefined;
type TPromiseExecutorResolver<T> = (value: T | PromiseLike<T>) => void;

/**
 *
 * @param callback - a callback that a will try to execute it until it reaches a truthy value or reaches a timeout
 * @param duration - how long will the function be attempting to execute
 * @param interval - execution interval
 * @returns a promise of a resolved callback
 */
export const tryForAWhile = async <T>(
  callback: () => TNullish<Promise<TNullish<T>>>,
  duration: number,
  interval = 100
): Promise<T> => {
  // Function exits with a simple timeout.
  let shouldContinueExecutionLoop = true;
  setTimeout(() => {
    shouldContinueExecutionLoop = false;
  }, duration);

  const promiseExecutor = (resolve: TPromiseExecutorResolver<T>, reject: CallableFunction): void => {
    const handleExecution = async () => {
      // Optionally call executeInTryCatch to loop until we reach an exit condition or resolve
      const handleRecursion = () => {
        // Exit condition when duration is over
        if (!shouldContinueExecutionLoop) {
          reject();
          return;
        }
        // Perform recursive calls in intervals
        setTimeout(() => handleExecution(), interval);
      };
      // attempt an execution, try again on failure
      try {
        const result = await callback();
        if (!result) {
          handleRecursion();
          return;
        }

        resolve(result);
      } catch (_) {
        handleRecursion();
      }
    };

    handleExecution();
  };

  return new Promise(promiseExecutor);
};

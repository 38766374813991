import * as Sentry from "@sentry/nextjs";
import type { CaptureContext } from "@sentry/types";

type TReportOptions = {
  data?: any;
  module?: string;
  component?: string;
  lib?: string;
};

const getErrorReportContext = (options?: TReportOptions): CaptureContext => {
  const extra = options?.data ? { data: options.data } : undefined;

  const tags =
    options?.module || options?.component || options?.lib
      ? { module: options?.module, component: options?.component, lib: options?.lib }
      : undefined;

  return { extra, tags };
};

const reportError = (err: any, options?: TReportOptions): void => {
  console.error(err, options);

  const context = getErrorReportContext(options);
  Sentry.captureException(err, context);
};

const reportMessage = (message: string, options?: TReportOptions): void => {
  console.error(message, options);

  const context = getErrorReportContext(options);
  Sentry.captureMessage(message, context);
};

export { reportError, reportMessage };

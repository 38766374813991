import getEnv from "@utils/getEnv";

const getRecaptchaKey = (domain?: string): string | undefined => {
  if (domain === "lt") {
    return getEnv("NEXT_PUBLIC_RECAPTCHA_KEY_LT");
  }
  if (domain === "lv") {
    return getEnv("NEXT_PUBLIC_RECAPTCHA_KEY_LV");
  }
  if (domain === "ee") {
    return getEnv("NEXT_PUBLIC_RECAPTCHA_KEY_EE");
  }
  return undefined;
};
export default getRecaptchaKey;

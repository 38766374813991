import { TFunction } from "next-i18next";
import * as Yup from "yup";
import { CREATE_USER_FEEDBACK_FORM_FIELDS } from "./constants";

export const getUserFeedbackValidationSchema = (t: TFunction) => {
  return Yup.object({
    [CREATE_USER_FEEDBACK_FORM_FIELDS.RATING]: Yup.number()
      .min(1, t("user-feedback-form-rating-is-required"))
      .max(5)
      .required(t("user-feedback-form-rating-is-required")),
    [CREATE_USER_FEEDBACK_FORM_FIELDS.DESCRIPTION]: Yup.string().max(500).optional(),
    [CREATE_USER_FEEDBACK_FORM_FIELDS.EMAIL]: Yup.string().max(255).optional(),
    [CREATE_USER_FEEDBACK_FORM_FIELDS.FILE]: Yup.mixed().optional(),
  });
};

import type { SystemStyleObject } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";

const disabledMixin = {
  color: "grey.accents-6",
  bg: "grey.light",
  border: "0px",
};

const Button: SystemStyleObject = {
  baseStyle: {
    fontWeight: "normal",
    lineHeight: "1",
    borderRadius: "0",
    px: "0.875rem",
    color: "white",
    flexShrink: 0,
    _hover: {
      textDecoration: "underline",
      _disabled: disabledMixin,
    },
    ...focusable,
    _disabled: {
      opacity: 1,
      ...disabledMixin,
      _hover: { textDecoration: "none" },
    },
    minHeight: "2.75rem",
  },

  sizes: {
    sm: { fontSize: "0.75rem", minWidth: "2.5rem" },
    md: {
      fontSize: "0.875rem",
      minWidth: "6.25rem",
    },
    lg: { minWidth: "16.75rem", height: "auto" },
    "icon-sm": { minWidth: "none", width: "2rem", minHeight: "none", height: "2rem" },
    "icon-md": {
      minWidth: "none",
      width: "2.5rem",
      minHeight: "none",
      height: "2.5rem",
    },
    "icon-lg": { minWidth: "none", width: "2.875rem", minHeight: "none", height: "2.875rem" },
  },

  variants: {
    main: {
      bg: "red.lead",
    },
    secondary: {
      bg: "grey.mid",
    },
    transparent: {
      bg: "transparent",
      color: "dpd-black",
      border: "1px solid",
      borderColor: "grey.mid",
    },
    "transparent-red": {
      bg: "transparent",
      border: "1px solid",
      borderColor: "red.lead",
      color: "red.lead",
    },
    "grey-light": {
      bg: "grey.light",
      color: "dpd-black",
      textShadow: "0px 0px 1px dpd-black",
    },
    "grey-dark": {
      bg: "grey.mid",
    },
    "grey-red": {
      bg: "grey.light",
      color: "dpd-black",
      border: "1px solid",
      borderColor: "red.lead",
    },
    dark: {
      bg: "dpd-black",
      color: "white",
    },
  },

  defaultProps: {
    size: "md",
    variant: "main",
  },
};

export default Button;

/* eslint-disable @typescript-eslint/naming-convention */
import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const TimePicker: ComponentMultiStyleConfig = {
  parts: ["option", "noOptionsMessage"],
  baseStyle: {
    option: {
      width: "3.4375rem",
      height: "1.875rem",
      minWidth: "initial",
      minHeight: "initial",
      borderRadius: "0.25rem",
      border: "none",
      _disabled: {
        background: "transparent",
        color: "grey.light",
        cursor: "not-allowed",
      },
      _hover: {
        background: "red.lead",
        color: "white",
        _disabled: {
          background: "transparent",
          color: "grey.light",
        },
      },
    },
    noOptionsMessage: {
      display: "flex",
      align: "center",
      justifyContent: "center",
      px: "2rem",
      py: "1rem",
    },
  },
};
export default TimePicker;

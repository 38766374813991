import { ComponentMultiStyleConfig } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";
import responsiveStyle from "@lib/theme/responsiveStyle";

const Tabs: ComponentMultiStyleConfig = {
  parts: ["panel", "tab", "text", "tabContainer"],
  baseStyle: {
    panel: { padding: 0 },
    tab: {
      borderBottom: "3px solid",
      borderColor: "transparent",
      position: "relative",
      top: "2px",
      paddingX: responsiveStyle({
        mobile: "0.75rem",
        desktop: "1rem",
      }),
      _active: { backgroundColor: "transparent" },
      _hover: { _active: { backgroundColor: "transparent" } },
      _selected: { borderColor: "red.lead", color: "red.lead" },
      _disabled: { color: "grey.light", cursor: "not-allowed" },
      ...focusable,
    },
    text: { color: "inherit", fontWeight: "light" },
    tabContainer: { textAlign: "start" },
  },
  sizes: {
    sm: { text: { fontSize: "0.75rem" }, tab: { px: "0.5rem", py: "0.375rem" } },
    md: {
      text: {
        fontSize: "1.0625rem",
      },
    },
    lg: {
      text: {
        fontSize: responsiveStyle({
          mobile: "0.825rem",
          desktop: "1.125rem",
        }),
      },
    },
  },
};

export default Tabs;

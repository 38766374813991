import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const boxWidthValues = { sm: "1.375rem", md: "3.615rem", lg: "4.25rem" };

const Badge: ComponentMultiStyleConfig = {
  parts: ["box", "text"],

  baseStyle: {
    box: { display: "inline-flex", justifyContent: "center", alignItems: "center", paddingX: "0.3125rem" },
    text: {
      fontSize: "0.75rem",
      fontWeight: "normal",
      color: "dpd-black",
      lineHeight: 1,
      flexShrink: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  variants: {
    success: { box: { backgroundColor: "green.accents-2" } },
    warning: { box: { backgroundColor: "yellow.light" } },
    error: { box: { backgroundColor: "red.warm" } },
    "grey-light": { box: { backgroundColor: "grey.light" } },
    "grey-dark": { box: { backgroundColor: "grey.mid" }, text: { color: "white" } },
  },

  sizes: {
    sm: {
      text: { fontSize: "0.625rem" },
      box: { minWidth: boxWidthValues.sm, height: "1rem" },
    },
    md: {
      box: { minWidth: boxWidthValues.md, height: "1.125rem" },
    },
    lg: {
      box: { minWidth: boxWidthValues.lg, height: "1.375rem" },
    },
  },
};

export default Badge;

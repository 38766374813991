import Cookies from "js-cookie";

let hasLocalStorage: boolean | null = null;

const isLocalStorageAvailable = () => {
  if (hasLocalStorage !== null) {
    return hasLocalStorage;
  }
  if (typeof window === "undefined") {
    return false;
  }
  const mod = "DPD_STORAGE_TEST";
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    hasLocalStorage = true;
    return true;
  } catch (e) {
    hasLocalStorage = false;
    return false;
  }
};

const setDataToStorage = (key: string, strData: string): void => {
  if (isLocalStorageAvailable()) {
    window.localStorage.setItem(key, strData);
    return;
  }
  Cookies.set(key, strData);
};

const getDataFromStorage = (key: string): string | undefined => {
  if (isLocalStorageAvailable()) {
    return window.localStorage.getItem(key) || undefined;
  }
  return Cookies.get(key);
};

const removeDataFromStorage = (key: string): void => {
  if (isLocalStorageAvailable()) {
    window.localStorage.removeItem(key);
    return;
  }
  Cookies.remove(key);
};

class PersistentBrowserStorage {
  public static removeData(key: string): void {
    removeDataFromStorage(key);
  }

  public static setData(key: string, data: any): void {
    const strData = JSON.stringify(data);
    setDataToStorage(key, strData);
  }

  public static getData(key: string): any | null {
    const strData = getDataFromStorage(key);
    if (strData) {
      return JSON.parse(strData);
    }
    return strData;
  }
}

export default PersistentBrowserStorage;

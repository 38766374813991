import getEnv from "@utils/getEnv";
import getHostName from "@utils/getHostName";
import getMultipleValuesFromString from "@utils/getMultipleValuesFromString";

let map: Record<string, string[]> | undefined;

const getLocaleMap = () => {
  if (map) {
    return map;
  }

  const ltDomainNames = getEnv("NEXT_PUBLIC_DOMAIN_LT");
  const lvDomainNames = getEnv("NEXT_PUBLIC_DOMAIN_LV");
  const eeDomainNames = getEnv("NEXT_PUBLIC_DOMAIN_EE");
  if (!ltDomainNames || !lvDomainNames || !eeDomainNames) {
    throw new Error("Invalid NEXT_PUBLIC_DOMAIN_*");
  }

  const ltLocales = getEnv("NEXT_PUBLIC_DOMAIN_LOCALES_LT");
  const lvLocales = getEnv("NEXT_PUBLIC_DOMAIN_LOCALES_LV");
  const eeLocales = getEnv("NEXT_PUBLIC_DOMAIN_LOCALES_EE");

  if (!ltLocales || !lvLocales || !eeLocales) {
    throw new Error("Invalid NEXT_PUBLIC_DOMAIN_LOCALES_*");
  }

  const LtLocaleConfig = [ltDomainNames, ltLocales];
  const LvLocaleConfig = [lvDomainNames, lvLocales];
  const EeLocaleConfig = [eeDomainNames, eeLocales];

  const domainLocaleMap: Record<string, string[]> = {};

  [LtLocaleConfig, LvLocaleConfig, EeLocaleConfig].forEach(([domainNames, domainLocales]) => {
    const cleanedNames = getMultipleValuesFromString(domainNames);
    const cleanedLocales = getMultipleValuesFromString(domainLocales);

    if (!cleanedNames.length || !cleanedLocales.length) {
      throw new Error("Invalid data in DOMAIN_LOCALES_* or NEXT_PUBLIC_DOMAIN_*");
    }

    cleanedNames.forEach((domain) => {
      domainLocaleMap[domain] = cleanedLocales;
    });
  });

  return domainLocaleMap;
};

const getLocaleConfig = (hostname: string): string[] => getLocaleMap()[getHostName(hostname)];

export default getLocaleConfig;

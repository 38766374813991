import { breakpoints } from "../media";

const chakraBreakpoints = (() => {
  const convertedBreakpoints = {};
  Object.entries(breakpoints).forEach(([key, val]) => {
    convertedBreakpoints[key] = `${val / 16}em`;
  });

  return convertedBreakpoints;
})();

export default chakraBreakpoints;

import { FC, useCallback, useContext } from "react";

import { FormikContext, useField } from "formik";

import { TFieldWrapperProps } from "./types";

const FormikField: FC<TFieldWrapperProps & { name: string }> = ({
  component: Component,
  isInvalid,
  message,
  name,
  onChange,
  onBlur,
  validate,
  hasSetFieldValue,
  hasOnChangeCallback = true,
  ...rest
}) => {
  const formik = useContext(FormikContext);
  if (!formik) {
    throw new Error(`"${name}" component must be within formik form when isFormik is set to true`);
  }

  const props = { isInvalid, message, name, onChange, onBlur, ...rest };

  const validateFunc = validate && ((value: any) => validate(value, props));

  const [{ onChange: onFieldChange, onBlur: onFieldBlur, ...restField }] = useField({ name, validate: validateFunc });

  const handleChange = useCallback(
    (e) => {
      onChange?.(e);
      if (hasOnChangeCallback) {
        onFieldChange(e);
      }
    },
    [hasOnChangeCallback, onChange, onFieldChange]
  );

  const handleBlur = useCallback(
    (e) => {
      onBlur?.(e);
      onFieldBlur(e);
    },
    [onBlur, onFieldBlur]
  );

  const formikSetFieldValue = formik.setFieldValue;

  const setFieldValue = useCallback((val) => formikSetFieldValue(name, val), [formikSetFieldValue, name]);

  return (
    <Component
      isInvalid={isInvalid || (!!formik.errors[name] && !!formik.touched[name])}
      message={message || formik.errors[name]}
      onChange={handleChange}
      onBlur={handleBlur}
      {...(hasSetFieldValue && { setFieldValue })}
      {...restField}
      {...rest}
      name={name}
    />
  );
};

export default FormikField;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const DataTable: ComponentMultiStyleConfig = {
  parts: ["row", "rowHeader", "expandedRow"],
  baseStyle: {
    row: {
      borderBottom: "1px solid",
      borderBottomColor: "grey.light",
      py: "0.25rem",
      fontSize: responsiveStyle({ mobile: "0.875rem", desktop: "1rem" }),
      _first: {
        borderTop: "1px solid",
        borderTopColor: "grey.light",
      },
    },
    rowHeader: {
      minHeight: "3.25rem",
      py: "0.5rem",
      px: "0.875rem",
    },
    expandableRow: {
      cursor: "pointer",
    },
    expandedRow: {
      background: responsiveStyle({ mobile: "grey.accents-4", desktop: "transparent" }),
    },
    expandContent: {
      background: responsiveStyle({ mobile: "grey.accents-4", desktop: "transparent" }),
      borderTop: responsiveStyle({ mobile: "1px solid", desktop: "none" }),
      borderTopColor: responsiveStyle({ mobile: "grey.accents-2", desktop: "transparent" }),
    },
    loadMoreContainer: {
      textAlign: "center",
      py: responsiveStyle({ mobile: "1rem", desktop: "3rem" }),
    },
  },
};
export default DataTable;

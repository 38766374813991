import { useCallback, useMemo, useState } from "react";

import debounce from "@utils/debounce";

import { TDataHookArgs, TDataHookReturns, TDataHookState } from "./types";

const useData = <T extends any, K = any | undefined>({
  fetcher,
  onError,
  onSuccess,
}: TDataHookArgs<T, K>): TDataHookReturns<T, K> => {
  const [{ isLoading, data }, setFetchState] = useState<TDataHookState<T>>({ isLoading: false, data: null });

  /**
   * @param args arguments passed to fetcher function defined in 'useData' hook.
   */
  const handleFetch = useCallback(
    async (args: K | undefined) => {
      try {
        setFetchState((oldData) => ({ ...oldData, isLoading: true }));
        const fetchedData = await fetcher(args);
        onSuccess?.(fetchedData);
        setFetchState({ isLoading: false, data: fetchedData });
        return fetchedData;
      } catch (err) {
        console.error(err);
        onError?.(err);
        setFetchState({ isLoading: false, data: null });
        return null;
      }
    },
    [fetcher, onSuccess, onError]
  );

  const debouncedFetchExecution = useMemo(() => debounce(handleFetch), [handleFetch]);

  const handleFetchWithDebounce = useCallback(
    (args: K | undefined) => {
      setFetchState((oldData) => ({ ...oldData, isLoading: true }));
      debouncedFetchExecution(args);
    },
    [debouncedFetchExecution, setFetchState]
  );

  return { isLoading, handleFetch, handleFetchWithDebounce, data };
};

export default useData;

import { FC } from "react";

import { createMedia } from "@artsy/fresnel";

export const breakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1200,
};
const AppMedia = createMedia({
  breakpoints,
});
export const mediaStyle = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;

export const Mobile: FC<{ children?: any }> = ({ children }) => <Media lessThan="md">{children}</Media>;
export const Desktop: FC<{ children?: any }>  = ({ children }) => <Media greaterThanOrEqual="md">{children}</Media>;

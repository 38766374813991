import { FC } from "react";

import FormikField from "./FormikField";
import { TFieldWrapperProps } from "./types";

const FieldWrapper: FC<TFieldWrapperProps> = ({
  isFormik,
  name,
  component: Component,
  validate,
  hasSetFieldValue,
  hasOnChangeCallback,
  ...componentProps
}) => {
  if (!isFormik) {
    return <Component name={name} {...componentProps} />;
  }

  if (!name) {
    throw new Error("Formik field must have a name");
  }

  return (
    <FormikField
      component={Component}
      validate={validate}
      hasSetFieldValue={hasSetFieldValue}
      hasOnChangeCallback={hasOnChangeCallback}
      name={name}
      {...componentProps}
    />
  );
};

export default FieldWrapper;

import { createContext } from "react";

import type { TAuthContextValue } from "../types";

const AuthContext = createContext<TAuthContextValue>({
  isLoggedIn: false,
  setAuthData: () => {},
  logout: () => {},
});

export default AuthContext;

import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const LanguageSelect: ComponentMultiStyleConfig = {
  parts: ["link", "linkText", "marker"],
  baseStyle: {
    link: { display: "flex", width: "2rem", height: "3.625rem", justifyContent: "center", alignItems: "center" },
    linkText: { fontSize: "0.875rem", width: "100%" },
    marker: {
      width: "1.375rem",
      height: "0.1875rem",
      backgroundColor: "red.lead",
      mx: "0.3125rem",
      top: 0,
      position: "absolute",
    },
  },
};
export default LanguageSelect;

import { LOCALE } from "@utils/constants";

import getLocaleConfig from "./getLocaleConfig";

type TLocaleReturns = {
  locales: string[];
  locale: string;
  isInvalidLocale: boolean;
};

export const getLocale = (host: string, requestLocale?: string): TLocaleReturns => {
  const locales = getLocaleConfig(host);
  const locale = (requestLocale === LOCALE.DEFAULT_LOCALE ? locales[0] : requestLocale) ?? locales[0];
  const isInvalidLocale = !locales || !locales.includes(locale);
  return { locales, locale, isInvalidLocale };
};

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";

const Select: ComponentMultiStyleConfig = {
  parts: ["selectBase", "selectList", "selectItem"],
  baseStyle: {
    selectBase: {
      position: "relative",
    },
    selectList: {
      width: "100%",
      minWidth: "20.875rem",
      mt: 0,
      bg: "white",
      rounded: 0,
      shadow: "none",
      border: "1px solid",
      borderColor: "dpd-black",
      my: "-1px",
      py: 0,
      "::-webkit-scrollbar": {
        width: "0.5rem",
        backgroundColor: "grey.light",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "grey.outline",
        borderRadius: "0.5rem",
      },
      ...focusable,
    },
    selectItem: {
      mx: 0,
      px: "1.125rem",
      py: "0.625rem",
      position: "relative",
      rounded: 0,
      _before: {
        content: '""',
        position: "absolute",
        left: "0.5rem",
        right: "0.5rem",
        top: "0",
        borderTop: "1px solid",
        borderTopColor: "grey.light",
      },
      _first: {
        _before: {
          borderTop: "none",
        },
      },
      ...focusable,
    },
  },
};
export default Select;

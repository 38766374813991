import { FC, ReactNode } from "react";

import { Box, useMultiStyleConfig } from "@chakra-ui/react";

const FormHint: FC<{ hint?: ReactNode }> = ({ hint }) => {
  const styles = useMultiStyleConfig("Common", {});
  return <>{hint && <Box sx={styles.hint}>{hint}</Box>}</>;
};

export default FormHint;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import Flags from "./data/flags.png";

const PhoneInput: ComponentMultiStyleConfig = {
  parts: [
    "codeSelectButton",
    "codeSelectButtonLabel",
    "codeSelectFlag",
    "codeSelectList",
    "codeSelectItem",
    "codeText",
  ],
  baseStyle: {
    codeSelectButton: {
      bg: "grey.light",
      color: "black",
      minWidth: "initial",
      height: "2.25rem",
      minHeight: "2.25rem",
      maxWidth: "5rem",
      lineHeight: 1,
      py: 0,
      px: "0.3125rem",
      _hover: {
        textDecoration: "none",
      },
    },
    codeSelectButtonLabel: {
      flex: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    codeSelectFlag: {
      backgroundImage: `url(${Flags.src || Flags})`,
      backgroundRepeat: "no-repeat",
      width: "16px",
      height: "11px",
    },
    codeSelectList: {
      minWidth: "20.5rem",
      maxHeight: "20.5rem",
      overflow: "hidden",
      borderRadius: 0,
      borderColor: "dpd-black",
      py: 0,
      "&>div::-webkit-scrollbar": {
        width: "0.5rem",
        backgroundColor: "grey.light",
      },
      "&>div::-webkit-scrollbar-thumb": {
        backgroundColor: "grey.outline",
        borderRadius: "0.5rem",
      },
    },
    codeSelectMenuItem: {
      position: "relative",
      px: "0.5rem",
      _before: {
        content: '""',
        position: "absolute",
        left: "0.5rem",
        right: "0.5rem",
        top: "1px",
        borderTop: "1px solid",
        borderTopColor: "grey.light",
      },
      _first: {
        _before: {
          borderTop: "none",
        },
      },
      _hover: {
        background: "grey.light",
      },
      _focus: {
        outline: 0,
        ".js-focus-visible &:not(.focus-visible)": {
          boxShadow: "none",
        },
        ".js-focus-visible &.focus-visible": {
          boxShadow: "outline",
        },
      },
    },
    codeSelectItem: {
      py: "0.625rem",
      lineHeight: "1",
      alignItems: "center",
      cursor: "pointer",
      height: "100%",
    },
    codeText: {
      color: "grey.mid",
      whiteSpace: "nowrap",
    },
  },
};
export default PhoneInput;

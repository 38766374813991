import { ThemeTypings } from "@chakra-ui/react";

type TResponsiveStyleParams<T> = {
  mobile: T;
  desktop: T;
  desktopWide?: T;
};

const responsiveStyle = <T = string | number>({
  mobile,
  desktop,
  desktopWide,
}: TResponsiveStyleParams<T>): Partial<Record<ThemeTypings["breakpoints"] | string, T>> => ({
  base: mobile,
  md: desktop,
  lg: desktopWide,
});
export default responsiveStyle;

import { FC, memo } from "react";

import FieldWrapper from "./FieldWrapper";
import type { TCompositeFormikFieldParams, TFormikFieldRequiredProps, TWrappedField } from "./types";

const withCompositeFormikField =
  ({ hasSetFieldValue, hasOnChangeCallback, validate }: TCompositeFormikFieldParams = {}) =>
  <T extends Record<string, unknown>>(Component: FC<T>): TWrappedField<T & TFormikFieldRequiredProps> => {
    const MemoComponent = memo(Component);
    const WrappedComponent = ({ isFormik = true, ...rest }: T & TFormikFieldRequiredProps) => (
      <FieldWrapper
        isFormik={isFormik}
        component={MemoComponent}
        validate={validate}
        hasSetFieldValue={hasSetFieldValue}
        hasOnChangeCallback={hasOnChangeCallback}
        {...rest}
      />
    );

    WrappedComponent.displayName = `${Component.displayName || "Component"}WithCompositeFormikField`;
    return WrappedComponent;
  };

export default withCompositeFormikField;

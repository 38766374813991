import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Message: ComponentMultiStyleConfig = {
  parts: ["box", "text", "container"],
  baseStyle: {
    box: { padding: "0.75rem" },
    text: { fontSize: "0.875rem" },
    container: { flexGrow: 1 },
  },
  variants: {
    grey: {
      box: { backgroundColor: "grey.light" },
      text: { fontWeight: "light" },
    },
    transparent: { box: { backgroundColor: "transparent" } },
    success: { box: { backgroundColor: "green.accents" } },
    warning: { box: { backgroundColor: "blue" } },
    error: { box: { backgroundColor: "red.transparent" } },
    danger: { box: { backgroundColor: "red.lead" }, text: { color: "white" } },
  },
  defaultProps: {
    variant: "grey",
  },
};

export default Message;

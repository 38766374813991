import { useState } from "react";
import { TCreateUserFeedbackForm } from "../types";

type TUserFeedbackFormInternalStateHookReturns = {
  userFeedbackForm: {
    errors?: Partial<Record<keyof TCreateUserFeedbackForm, string | undefined>>;
  };
  clearErrors: () => void;
  setErrors: (errors?: Partial<Record<keyof TCreateUserFeedbackForm, string | undefined>>) => void;
};
type TUserFeedbackFormInternalState = {
  errors?: Partial<Record<keyof TCreateUserFeedbackForm, string | undefined>>;
};

const updateState =
  <T extends Record<any, any>>(newState: Partial<T>) =>
  (oldState: T): T => ({ ...oldState, ...newState });

export const useUserFeedbackFormInternalState = (): TUserFeedbackFormInternalStateHookReturns => {
  const [userFeedbackForm, setUserFeedbackForm] = useState<TUserFeedbackFormInternalState>({
    errors: undefined,
  });

  const clearErrors = () => setUserFeedbackForm({ errors: undefined });

  const setErrors = (errors?: Partial<Record<keyof TCreateUserFeedbackForm, string | undefined>>) =>
    setUserFeedbackForm(updateState<typeof userFeedbackForm>({ errors }));

  return { userFeedbackForm, clearErrors, setErrors };
};

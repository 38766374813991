import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Modal: ComponentMultiStyleConfig = {
  parts: ["overlay", "modal", "headerWrapper", "header", "headerText", "body", "footer", "button"],

  baseStyle: {
    overlay: {
      backgroundColor: "overlay-black",
    },
    modal: {
      borderRadius: "0px",
      marginX: "0.75rem",
      maxWidth: "none",
    },
    button: {
      borderRadius: "0px",
      position: "relative",
      right: 0,
      top: 0,
      backgroundColor: "white",
      _hover: { _active: { backgroundColor: "white" } },
      _active: { backgroundColor: "white" },
    },
    header: { flexGrow: 1 },
    headerText: { fontSize: "1.125rem", lineHeight: "120%" },
    headerWrapper: { padding: 0, margin: 0, position: "relative", display: "flex", justifyContent: "flex-end" },
    footer: {
      paddingTop: 0,
    },
    footerButton: {
      flexBasis: "30%",
      whiteSpace: "normal",
    },
  },

  variants: {
    mobile: {
      header: {
        paddingX: "0.75rem",
        paddingTop: "1.125rem",
        paddingBottom: 0,
      },
      body: { paddingX: "0.75rem", paddingY: "1.125rem" },
      footer: { paddingX: "0.75rem", paddingBottom: "1.125rem" },
      button: {
        marginTop: "0.75rem",
        marginRight: "0.3125rem",
      },
    },
    desktop: {
      modal: { width: "37.5rem" },
      header: {
        paddingX: "1.5rem",
        paddingTop: "1.25rem",
        paddingBottom: 0,
      },
      body: { paddingX: "1.5rem", paddingY: "1.25rem" },
      footer: { paddingX: "1.5rem", paddingBottom: "1.25rem" },
      button: {
        marginTop: "0.75rem",
        marginRight: "0.875rem",
      },
    },
  },

  defaultProps: {
    variant: "desktop",
  },
};

export default Modal;

import { i18n } from "next-i18next";
import { AnySchema } from "yup";

import { DeliveryMethod, OrderFields } from "../../constants";

const receiverRequiredFields = [OrderFields.RECEIVER_PHONE_NUMBER];
const receiverNonDomesticRequiredFields = [OrderFields.RECEIVER_NAME];
const receiverDoorToPudoRequiredFields = [OrderFields.RECEIVER_LOCKER];

const receiverDoorToDoorRequiredFields = [
  OrderFields.RECEIVER_NAME,
  OrderFields.RECEIVER_PHONE_NUMBER,
  OrderFields.RECEIVER_STREET,
  OrderFields.RECEIVER_CITY,
  OrderFields.RECEIVER_ZIP_CODE,
];

const getRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (receiverRequiredFields.includes(fieldName)) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }
  return schema;
};

const getNonDomesticRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (receiverNonDomesticRequiredFields.includes(fieldName)) {
    return schema.when(OrderFields.IS_DOMESTIC, ([isDomestic], domesticSchema: AnySchema) =>
      !isDomestic ? domesticSchema.required(i18n?.t("order-message-field_is_required")) : domesticSchema
    );
  }
  return schema;
};

const getDeliveryMethodRequiredFieldSchema = (
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod,
  schema: AnySchema
) => {
  if (deliveryMethod === DeliveryMethod.DoorToDoor && receiverDoorToDoorRequiredFields.includes(fieldName)) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }

  if (deliveryMethod === DeliveryMethod.DoorToPudo && receiverDoorToPudoRequiredFields.includes(fieldName)) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }
  return schema;
};

const getReceiverRequiredFieldSchema = (
  schema: AnySchema,
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod
): AnySchema => {
  let schemaWithValidation = schema;

  schemaWithValidation = getRequiredFieldSchema(fieldName, schemaWithValidation);
  schemaWithValidation = getNonDomesticRequiredFieldSchema(fieldName, schemaWithValidation);
  schemaWithValidation = getDeliveryMethodRequiredFieldSchema(fieldName, deliveryMethod, schemaWithValidation);

  return schemaWithValidation;
};
export { getReceiverRequiredFieldSchema };

/* eslint-disable @typescript-eslint/naming-convention */
import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import { inputControlFocusable } from "@lib/theme/mixins";

const Checkbox: ComponentMultiStyleConfig = {
  parts: [],
  baseStyle: {
    container: {
      ".chakra-checkbox__input": {
        ...inputControlFocusable(".chakra-checkbox__control"),
      },
    },
    label: {
      fontSize: "inherit",
      _disabled: {
        color: "grey.mid",
        opacity: 1,
      },
    },
    control: {
      bg: "white",
      border: "1px solid",
      borderColor: "grey.mid",
      borderRadius: 0,
      color: "dpd-black",
      _checked: {
        bg: "white",
        color: "dpd-black",
        borderColor: "grey.mid",
        _hover: {
          bg: "white",
          borderColor: "grey.mid",
        },
        _disabled: {
          bg: "grey.light",
          color: "grey.mid",
          borderColor: "grey.mid",
        },
      },

      _disabled: {
        bg: "grey.light",
        color: "grey.mid",
        borderColor: "grey.mid",
      },
      _invalid: {
        borderColor: "red.lead",
        boxShadow: "error",
        _disabled: {
          bg: "grey.light",
          color: "grey.mid",
        },
      },
    },
  },

  sizes: {
    sm: {
      control: { h: "1rem", w: "1rem" },
      label: { fontSize: "inherit" },
      icon: { fontSize: "0.625rem" },
    },

    md: {
      control: { w: "1.625rem", h: "1.625rem" },
      label: { fontSize: "inherit" },
      icon: { fontSize: "1rem" },
    },

    lg: {
      control: { w: "1.875rem", h: "1.875rem" },
      label: { fontSize: "inherit" },
      icon: { fontSize: "1.125rem" },
    },

    defaultProps: {
      size: "md",
    },
  },
};

export default Checkbox;

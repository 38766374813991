/* eslint-disable @typescript-eslint/naming-convention */
import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";

const DatePicker: ComponentMultiStyleConfig = {
  parts: ["rangeDateLabel", "calendar", "chevronIcon", "dateSelectWrapper", "dateSelectButton"],
  baseStyle: {
    chevronIcon: { fontSize: "1.125rem" },
    dateSelectWrapper: {
      "& select": { px: 0, h: "2.5rem", width: "auto", cursor: "pointer", textTransform: "capitalize" },
      "& select + div": { display: "none" },
    },
    dateSelectButton: {
      color: "dpd-black",
      _disabled: { bg: "transparent", color: "grey.mid", cursor: "not-allowed", opacity: "0.8" },
      _hover: { bg: "transparent" },
    },
    rangeDateLabel: {
      py: "0.25rem",
      background: "grey.accents",
      borderRadius: "0.25rem",
      textAlign: "center",
      color: "black",
      fontSize: "0.875rem",
      margin: "0.25rem",
    },

    calendar: {
      ".react-datepicker": {
        fontFamily: "body",
        border: "none",
      },
      ".react-datepicker__header": {
        background: "white",
        px: 0,
        mx: "1rem",
        borderBottomColor: "grey.light",
      },
      ".react-datepicker__current-month": {
        fontWeight: "400",
        color: "dpd-black",
      },
      ".react-datepicker__navigation": {
        ...focusable,
      },
      ".react-datepicker__navigation-icon": {
        _before: {
          top: 0,
          bottom: 0,
          margin: "auto",
          borderColor: "grey.outline",
        },
      },
      ".react-datepicker__navigation:hover .react-datepicker__navigation-icon": {
        _before: {
          borderColor: "dpd-black",
        },
      },
      ".react-datepicker__day-name": {
        width: "1.875rem",
        color: "dpd-black",
        textTransform: "uppercase",
      },
      ".react-datepicker__day": {
        width: "1.875rem",
        height: "1.875rem",
        fontSize: "0.875rem",
        paddingY: "0.5rem",
        borderRadius: "100%",
        display: "inline-block",
        textAlign: "center",
        color: "dpd-black",
        lineHeight: 1,
        _hover: {
          backgroundColor: "grey.light",
        },
        ...focusable,
      },
      ".react-datepicker__day--weekend": {
        color: "grey.outline",
      },
      ".react-datepicker__day--outside-month": {
        visibility: "hidden",
      },
      ".react-datepicker__day--disabled": {
        color: "grey.light",
        cursor: "default",
        _hover: {
          background: "transparent",
        },
      },
      ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day--in-range": {
        background: "red.lead",
        color: "white",
        _hover: {
          background: "red.lead",
        },
      },
      ".react-datepicker__day--in-selecting-range": {
        background: "red.lead",
        color: "white",
        _hover: {
          background: "red.lead",
        },
      },
      [".react-datepicker__month--selecting-range " +
      ".react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range)"]: {
        background: "transparent",
      },
    },
  },
};
export default DatePicker;

import crypto from "crypto";

import getEnv from "@utils/getEnv";

const ALGORITHM = "aes-256-cbc";

export type TEncrypted = {
  token: string;
  iv: string;
};

export const encryptString = (message: string | null): TEncrypted => {
  if (message === null) {
    throw new Error("Message cannot be null");
  }
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(ALGORITHM, getEnv("ENCRYPT_SECRET_KEY") || "", iv);
  const encryptedData = Buffer.concat([cipher.update(message), cipher.final()]);

  return { token: encryptedData.toString("hex"), iv: iv.toString("hex") };
};

export const decryptString = (message: TEncrypted): string => {
  if (message === null) {
    throw new Error("Message cannot be null");
  }

  const decipher = crypto.createDecipheriv(
    ALGORITHM,
    getEnv("ENCRYPT_SECRET_KEY") || "",
    Buffer.from(message.iv, "hex")
  );

  const decrypted = Buffer.concat([decipher.update(Buffer.from(message.token, "hex")), decipher.final()]);

  return decrypted.toString();
};

import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Icon from "@components/Icon";
import { ContextMessage, useMessage } from "@components/Message";
import useUserFeedbackForm from "@modules/userFeedback/hooks/useUserFeedbackForm";
import {
  CREATE_USER_FEEDBACK_FORM_FIELDS,
  MESSAGE_ZONE_USER_FEEDBACK_FORM,
} from "@modules/userFeedback/utils/constants";
import { getUserFeedbackValidationSchema } from "@modules/userFeedback/utils/validation";
import { Formik } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "next-i18next";
import { IconButton } from "@components/buttons";
import { useRouter } from "next/router";
import ScreenshotTool from "@components/ScreenshotTool";
import useAuthData from "@modules/auth/hooks/useAuthData";
import UserFeedbackFormFields from "../UserFeedbackFormFields";

const UserFeedbackForm: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const setMessage = useMessage();
  const [authData] = useAuthData();

  const { email } = authData || {};

  const [isInScreenshotMode, setIsInScreenshotMode] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number } | null>(null);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const {
    handleFeedbackSubmit,
    errors: apiResponseErrors,
    isLoading,
    clearErrors,
  } = useUserFeedbackForm(onClose, () => {
    setMessage({
      message: t("user-feedback-message-successfully-saved"),
      props: {
        variant: "success",
        icon: <Icon icon="success" size="1.75rem" />,
        isAnimated: false,
        canClose: true,
      },
      zone: MESSAGE_ZONE_USER_FEEDBACK_FORM,
      autoClearMs: 3000,
    });
  });

  return (
    <>
      <Formik
        onSubmit={handleFeedbackSubmit}
        initialValues={{
          [CREATE_USER_FEEDBACK_FORM_FIELDS.RATING]: 0,
          [CREATE_USER_FEEDBACK_FORM_FIELDS.DESCRIPTION]: "",
          [CREATE_USER_FEEDBACK_FORM_FIELDS.EMAIL]: email || "",
          [CREATE_USER_FEEDBACK_FORM_FIELDS.FILE]: "",
          [CREATE_USER_FEEDBACK_FORM_FIELDS.RECAPTCHA]: "",
        }}
        validationSchema={getUserFeedbackValidationSchema(t)}
      >
        {({ handleSubmit, resetForm, setFieldValue, values }) => {
          const screenshot = values[CREATE_USER_FEEDBACK_FORM_FIELDS.FILE];

          const handleClose = () => {
            onClose();
            clearErrors();
            resetForm();
          };

          return (
            <>
              <Box opacity={isInScreenshotMode ? 0 : 1}>
                <Popover isOpen={isOpen} onClose={handleClose} closeOnBlur={false} placement="left" gutter={16}>
                  <PopoverTrigger>
                    <IconButton
                      icon={<Icon icon="feedback" size="1.5rem" />}
                      variant="dark"
                      onClick={onToggle}
                      position="fixed"
                      bottom="50%"
                      right="0"
                    />
                  </PopoverTrigger>
                  <PopoverContent minW="sm" zIndex={999}>
                    <PopoverArrow />
                    <PopoverCloseButton top={4} />
                    <PopoverHeader p={4} borderBottom={0}>
                      {t("user-feedback-create-feedback")}
                    </PopoverHeader>

                    <ContextMessage messageZone={MESSAGE_ZONE_USER_FEEDBACK_FORM} />

                    <PopoverBody px={6} py={5}>
                      <UserFeedbackFormFields
                        screenshot={screenshot}
                        setIsInScreenshotMode={setIsInScreenshotMode}
                        apiResponseErrors={apiResponseErrors}
                        setCursorPosition={setCursorPosition}
                      />
                    </PopoverBody>

                    <PopoverFooter
                      display="flex"
                      justifyContent="space-between"
                      borderTop={0}
                      px={6}
                      pb={5}
                      pt={2}
                      alignItems="center"
                    >
                      <Text
                        color="red.lead"
                        cursor="pointer"
                        fontSize="sm"
                        onClick={() => {
                          router.push("/faq");
                          handleClose();
                        }}
                      >
                        {t("layout-footer-faq")}
                      </Text>
                      <Button onClick={() => handleSubmit()} isLoading={isLoading}>
                        {t("user-feedback-form-submit")}
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </Box>

              <ScreenshotTool
                isInScreenshotMode={isInScreenshotMode}
                toggleScreenshotMode={setIsInScreenshotMode}
                onCapture={(screenshot) => {
                  setFieldValue(CREATE_USER_FEEDBACK_FORM_FIELDS.FILE, screenshot, true);
                }}
                cursorPosition={cursorPosition}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default UserFeedbackForm;

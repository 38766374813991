import { FC } from "react";

import { chakra } from "@chakra-ui/react";
import dynamic from "next/dynamic";

const Marquee = dynamic(() => import("./Marquee"));

const OptionalMarquee: FC<{ duration?: number; isAnimated?: boolean, children?: any }> = ({ children, isAnimated, duration }) =>
  isAnimated ? <Marquee duration={duration}>{children}</Marquee> : <chakra.span>{children}</chakra.span>;

export default OptionalMarquee;

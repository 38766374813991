import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Popover: ComponentMultiStyleConfig = {
  parts: ["popper"],
  baseStyle: {
    popper: {
      zIndex: 15,
    },
  },
};

export default Popover;

/* eslint-disable import/prefer-default-export */
import { CSSObject } from "@chakra-ui/react";

export const focusable: CSSObject = {
  _focus: {
    boxShadow: "outline",
    outline: 0,
    ".js-focus-visible &:not(.focus-visible)": {
      boxShadow: "none",
    },
    ".js-focus-visible &.focus-visible": {
      boxShadow: "outline",
    },
  },
};

export const inputControlFocusable = (controlClassName: string): CSSObject => ({
  _focus: {
    outline: 0,
    [`+ ${controlClassName}`]: {
      boxShadow: "outline",
    },
    [`.js-focus-visible &:not(.focus-visible) + ${controlClassName}`]: {
      boxShadow: "none",
    },
    [`.js-focus-visible &.focus-visible + ${controlClassName}`]: {
      boxShadow: "outline",
    },
  },
});

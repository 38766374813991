import { FC, isValidElement, useState, useEffect } from "react";

import { useMultiStyleConfig, Textarea as BaseTextarea } from "@chakra-ui/react";
import { i18n, useTranslation } from "next-i18next";

import Counter from "@components/Counter";

import { withCompositeFormikField } from "@lib/withFormikField";
import ifEmojiExists from "@utils/ifEmojiExists";

import BaseInput from "./BaseInput";
import type { TTextareaProps } from "./types";

const Textarea: FC<TTextareaProps> = ({
  isFormik,
  id,
  name,
  label,
  value,
  message,
  hint,
  placeholder,
  isDisabled,
  isInvalid,
  isRequired,
  leftAddon,
  rightAddon,
  leftSpacing,
  rightSpacing,
  maxLength,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig("TextInput", {});
  const messageLabel = message || (value ? label : undefined);

  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [internalMessage, setInternalMessage] = useState("");

  const handleInputChange = (e) => {
    if (rest.onChange) {
      rest.onChange(e);
    }
    setInputValue(e.target.value);
  };
  useEffect(() => {
    if (value) {
      setInputValue(value.toString());
    }
  }, [value]);
  const height = messageLabel ? "calc(100% - 1.125rem)" : "100%";
  return (
    <BaseInput
      inputType="textarea"
      message={internalMessage || messageLabel}
      hint={hint}
      isDisabled={isDisabled}
      isInvalid={!!internalMessage || isInvalid}
      leftAddon={leftAddon}
      rightAddon={
        maxLength && isActive ? (
          <Counter maxLength={maxLength} currentLength={inputValue.length} isError={inputValue.length === maxLength} />
        ) : (
          rightAddon
        )
      }
      leftSpacing={leftSpacing}
      rightSpacing={rightSpacing}
      id={id || name}
      name={name}
    >
      <BaseTextarea
        isRequired={isRequired}
        placeholder={placeholder || label}
        value={value}
        sx={{ ...styles.input, ...styles.textarea, height, ...sx }}
        pt={messageLabel ? "0" : "0.5rem"}
        mt={messageLabel ? "1.125rem" : "0"}
        pl={isValidElement(leftAddon) ? "3rem" : "1rem"}
        id={id || name}
        name={name}
        maxLength={maxLength}
        onFocus={() => setIsActive(true)}
        {...rest}
        onChange={handleInputChange}
        onBlur={(e) => {
          if (!isFormik) {
            const isEmojiExists = ifEmojiExists(e.target.value);
            setInternalMessage(isEmojiExists ? t("common-invalid_emoji_input") : "");
          }
          if (rest.onBlur) {
            rest.onBlur(e);
          }
          setIsActive(false);
        }}
        variant="unstyled"
      />
    </BaseInput>
  );
};


const validate = (value?: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  const isEmojiExists = ifEmojiExists(value);

  return isEmojiExists ? i18n?.t("common-invalid_emoji_input") : undefined;
};
export default withCompositeFormikField({ validate })(Textarea);

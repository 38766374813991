import { FC } from "react";

import { FormLabel, useMultiStyleConfig, CSSObject, FormLabelProps } from "@chakra-ui/react";

const FormMessage: FC<
  { message: string | undefined; isInvalid?: boolean; hasNoSpacing?: boolean; sx?: CSSObject } & FormLabelProps
> = ({ message, isInvalid, hasNoSpacing, sx, ...props }) => {
  const styles = useMultiStyleConfig("Common", {});
  return (
    <>
      {message && (
        <FormLabel
          sx={{ ...(isInvalid ? styles.formError : styles.formMessage), ...(hasNoSpacing && { p: 0, m: 0 }), ...sx }}
          title={message}
          {...props}
        >
          {message}
        </FormLabel>
      )}
    </>
  );
};

export default FormMessage;

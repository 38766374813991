import type { ComponentMultiStyleConfig, CSSObject } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";

const interactive: CSSObject = {
  cursor: "pointer",
  fontSize: "0.75rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textDecoration: "none",
};
const container: CSSObject = {
  backgroundColor: "grey.light",
  width: "2.625rem",
};
const Dropdown: ComponentMultiStyleConfig = {
  parts: ["select", "menu", "item", "wrapper"],
  baseStyle: {
    select: {
      ...interactive,
      ...container,
      ...focusable,
      height: "2.5rem",
      _hover: { textDecoration: "underline" },
    },
    menu: { ...container, borderRadius: "0px", border: "0px", paddingY: "1px", minWidth: "2.625rem" },
    item: {
      ...interactive,
      ...focusable,
      height: "1.825rem",
      my: "1px",
      px: 0,
      width: "full",
      _hover: { backgroundColor: "grey.accents" },
    },
    wrapper: {
      ...container,
      ...focusable,
    },
  },
};

export default Dropdown;

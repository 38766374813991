import { cloneElement, Fragment } from "react";

import { TWrappedComponent } from "../components/Layout/types";

const createLayout = (Component: any, domain: string): TWrappedComponent => {
  const Container = Component.Layout;
  if (!Container) {
    return Fragment;
  }

  return ({ children }) => cloneElement(Container, { domain }, children);
};

export default createLayout;

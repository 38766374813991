import { TCreateUserFeedbackForm, TCreateUserFeedbackRequest } from "../types";
import { CREATE_USER_FEEDBACK_FORM_FIELDS } from "./constants";

const mapRequestErrorsToForm = ({
  rating,
  description,
  email,
  file,
}: Partial<Record<keyof TCreateUserFeedbackRequest, string | undefined>>): Partial<
  Record<keyof TCreateUserFeedbackForm, string | undefined>
> => ({
  [CREATE_USER_FEEDBACK_FORM_FIELDS.RATING]: rating,
  [CREATE_USER_FEEDBACK_FORM_FIELDS.DESCRIPTION]: description,
  [CREATE_USER_FEEDBACK_FORM_FIELDS.EMAIL]: email,
  [CREATE_USER_FEEDBACK_FORM_FIELDS.FILE]: file,
});

export default mapRequestErrorsToForm;

import emojis from "@data/emoji.json";

const ifEmojiExists = (str: string): boolean => {
  let count = 0;

  emojis.forEach((icon) => {
    if (str.indexOf(icon) !== -1) {
      count += 1;
    }
  });

  return count > 0;
};

export default ifEmojiExists;

import type { SystemStyleObject } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";

const Link: SystemStyleObject = {
  baseStyle: {
    color: "dpd-black",
    fontWeight: "normal",
    display: "inline-flex",
    _hover: { textDecoration: "underline" },
    ...focusable,
  },

  sizes: {
    sm: { fontSize: "0.75rem" },
    md: { fontSize: "0.875rem" },
    lg: { fontSize: "1rem" },
  },
  variants: {
    dark: { color: "dpd-black" },
    red: { color: "red.lead" },
  },
  defaultProps: {
    variant: "dark",
    size: "md",
  },
};

export default Link;

import { useCallback, useEffect, useState } from "react";

import { reportError } from "@utils/errorReporting";
import PersistentBrowserStorage from "@utils/PersistentBrowserStorage";

import {
  DeliveryMethod,
  OrderFields,
  OrderStep,
  ORDER_STORAGE_KEY,
  ORDER_STORAGE_TIMEOUT,
  ORDER_STORAGE_TIME_KEY,
  SHIPMENT_STEP_DELIVERY_METHODS,
} from "../../constants";
import validateOrderFormData, { TOrderFormData } from "../../models/orderForm";

type TOrderStorageReturn = {
  getData: (currentStep?: OrderStep) => TOrderFormData | undefined;
  getDataTime: () => number | undefined;
  storeData: (data: TOrderFormData) => void;
  clearData: () => void;
  updateData: () => void;
};

const getDataStorageTime = () => PersistentBrowserStorage.getData(ORDER_STORAGE_TIME_KEY);

const updateDataStorageTime = () => {
  PersistentBrowserStorage.setData(ORDER_STORAGE_TIME_KEY, Date.now());
};

const clearDataStorageTime = () => {
  PersistentBrowserStorage.removeData(ORDER_STORAGE_TIME_KEY);
};

const getPreviousStep = (step: OrderStep, deliveryMethod: DeliveryMethod): OrderStep => {
  if (step === OrderStep.ShipmentDetails) {
    return OrderStep.PackageInfo;
  }
  if (step === OrderStep.Summary) {
    const isShipmentStep = deliveryMethod && SHIPMENT_STEP_DELIVERY_METHODS.includes(deliveryMethod);
    return isShipmentStep ? OrderStep.ShipmentDetails : OrderStep.ReceiverDetails;
  }
  return OrderStep.PackageInfo;
};

const getStateFromStorage = (): TOrderFormData | undefined => {
  try {
    const dataTime = getDataStorageTime();
    if (dataTime && Date.now() - dataTime > ORDER_STORAGE_TIMEOUT) {
      PersistentBrowserStorage.removeData(ORDER_STORAGE_KEY);
      clearDataStorageTime();
      return undefined;
    }

    const rawData = PersistentBrowserStorage.getData(ORDER_STORAGE_KEY);
    if (!rawData || typeof rawData !== "object" || !rawData[OrderFields.CURRENT_STEP]) {
      return undefined;
    }

    // Need to change step to previous before validation
    // because current step data does not have to be valid yet

    const currentStep = rawData[OrderFields.CURRENT_STEP];
    const deliveryMethod = rawData?.[OrderFields.DELIVERY_METHOD];

    const dataForValidation = {
      ...rawData,
      [OrderFields.CURRENT_STEP]: getPreviousStep(currentStep, deliveryMethod as DeliveryMethod),
    };

    const data = validateOrderFormData(dataForValidation);
    data[OrderFields.CURRENT_STEP] = currentStep; // Setting back step to the on which was stored
    return data;
  } catch (err) {
    reportError(err, { module: "order" });
    return undefined;
  }
};

const setStateToStorage = (state: TOrderFormData | undefined): void => {
  try {
    if (!state) {
      PersistentBrowserStorage.removeData(ORDER_STORAGE_KEY);
      clearDataStorageTime();
      return;
    }
    PersistentBrowserStorage.setData(ORDER_STORAGE_KEY, state);
    updateDataStorageTime();
  } catch (err) {
    reportError(err, { module: "order" });
  }
};

const useOrderStorage = (): TOrderStorageReturn => {
  const [data, setData] = useState<TOrderFormData | undefined>(() => getStateFromStorage());

  const getData = useCallback(
    (currentStep?: OrderStep) =>
      ({
        ...data,
        ...(currentStep && { [OrderFields.CURRENT_STEP]: currentStep as string }),
      } as TOrderFormData),
    [data]
  );

  const storeData = useCallback((newData: TOrderFormData) => {
    setData(newData);
  }, []);

  const clearData = useCallback(() => {
    setData(undefined);
    setStateToStorage(undefined);
  }, []);

  const updateData = useCallback(() => {
    updateDataStorageTime();
  }, []);

  const getDataTime = useCallback(() => getDataStorageTime(), []);

  useEffect(() => {
    setStateToStorage(data);
  }, [data]);

  return { getData, storeData, clearData, updateData, getDataTime };
};
export default useOrderStorage;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const OptionButton: ComponentMultiStyleConfig = {
  parts: ["button", "row", "rowItem"],
  baseStyle: {
    button: {
      fontSize: "0.875rem",
      px: "0.5rem",
      _hover: { textDecoration: "underline" },
      _disabled: {
        cursor: "not-allowed",
        color: "grey.accents-6",
        bg: "grey.light",
        border: "0px",
        _hover: { textDecoration: "none" },
        _active: { bg: "grey.light" },
      },
    },
    row: { marginLeft: "-0.625rem", marginTop: "-0.625rem" },
    rowItem: {
      paddingLeft: "0.625rem",
      paddingTop: "0.625rem",
      marginTop: "0",
      marginLeft: "0",
    },
  },
};
export default OptionButton;

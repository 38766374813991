import fetchApi from "@lib/api-fetcher/fetchApi";
import { API_ROUTES } from "@utils/constants";

import validateAuthDataResponse from "../models/authData";
import { TAuthData } from "../types";

const updateToken = async (authData: TAuthData): Promise<TAuthData | null> => {
  try {
    const response = await fetchApi({
      url: API_ROUTES.TOKEN_REFRESH,
      method: "POST",
      data: {
        refreshToken: authData.refreshToken,
      },
    });

    const { token, refreshToken, isPasswordExpired, hasInvalidDefaultAddresses } = validateAuthDataResponse(response.data);

    // Endpoint returns more info than needed, need to remap it leaving only the data needed
    return { token, refreshToken, isPasswordExpired, hasInvalidDefaultAddresses };
  } catch (err) {
    // Token refresh failed, maybe refresh token expired or something.
    return null;
  }
};
export default updateToken;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Common: ComponentMultiStyleConfig = {
  parts: [
    "formFieldLabel",
    "formMessage",
    "formError",
    "bodyText",
    "smallText",
    "tinyText",
    "sectionHeadingText",
    "iconDropdown",
    "iconDropdownContent",
    "fullWidthVStack",
  ],
  baseStyle: {
    formFieldLabel: {
      fontSize: "1.125rem",
      _disabled: {
        color: "black",
      },
    },
    formMessage: {
      fontSize: "0.75rem",
      color: "grey.mid",
      px: "0.188rem",
      mt: "0.375rem",
      _disabled: {
        color: "grey.mid",
      },
    },
    formError: {
      fontSize: "0.75rem",
      color: "red.lead",
      px: "0.188rem",
      mt: "0.375rem",
      _disabled: {
        color: "red.lead",
      },
    },
    formBox: {
      alignItems: "start",
    },
    formGreyBox: {
      mt: "0.5rem",
      alignItems: "start",
      p: "0.625rem",
      bg: "grey.accents-4",
    },
    hint: {
      fontSize: "0.75rem",
      color: "grey.mid",
      mt: "0.375rem",
      px: "0.188rem",
    },
    bodyText: { fontSize: "1rem", lineHeight: "120%" },
    smallText: { fontSize: "0.875rem" },
    tinyText: { fontSize: "0.75rem" },
    bigText: { fontSize: "1.125rem" },
    sectionHeadingText: { fontSize: "1.875rem", fontWeight: "light", lineHeight: "120%" },
    paragraphHeadingText: { fontSize: "1.125rem", fontWeight: "light", lineHeight: "120%" },
    iconDropdown: {
      border: "none",
      boxShadow: "dropdown",
      width: "auto",
      _focus: {
        ".js-focus-visible &": {
          outline: 0,
          boxShadow: "dropdown",
        },
      },
    },
    iconDropdownContent: {
      padding: 0,
    },
    fullWidthVStack: {
      alignItems: "stretch",
    },
  },
};

export default Common;

import { FC, useCallback } from "react";

import { Box, HStack, useDisclosure, useMultiStyleConfig, Flex } from "@chakra-ui/react";

import { IconButton } from "@components/buttons";
import Icon from "@components/Icon";
import { OptionalMarquee } from "@components/Marquee";

import type { TMessageProps } from "./types";

const getMessageBoxStyles = (styles, padding, backgroundColor, isAnimated: boolean | undefined) => ({
  ...styles.box,
  ...(padding && { padding }),
  ...(backgroundColor && { backgroundColor }),
  ...(isAnimated && { paddingLeft: 0, paddingRight: 0 }),
});

const Message: FC<TMessageProps> = ({
  icon,
  children,
  padding = "0.75rem",
  canClose = false,
  variant = "grey",
  align = "center",
  justify = "start",
  isAnimated,
  animationDuration,
  backgroundColor,
  textBoxWidth,
  sx,
  onClose,
  noScript,
}) => {
  const { isOpen, onClose: onCloseInternal } = useDisclosure({ defaultIsOpen: true });
  const styles = useMultiStyleConfig("Message", { variant });

  const handleClose = useCallback(() => {
    onCloseInternal();
    if (onClose) {
      onClose();
    }
  }, [onClose, onCloseInternal]);

  const message = (
    <HStack
      sx={{ ...getMessageBoxStyles(styles, padding, backgroundColor, isAnimated), ...sx }}
      alignItems={align}
      justify={justify}
    >
      {icon && !isAnimated && <Flex align="center">{icon}</Flex>}
      <Box sx={{ ...styles.container, ...(textBoxWidth && { flexBasis: textBoxWidth, flexGrow: 0, px: "0.5rem" }) }}>
        <OptionalMarquee isAnimated={isAnimated} duration={animationDuration}>
          <Box sx={styles.text}>{children}</Box>
        </OptionalMarquee>
      </Box>
      {canClose && !isAnimated && (
        <IconButton variant="transparent" onClick={handleClose} icon={<Icon icon="close" />} size="sm" />
      )}
    </HStack>
  );

  if (noScript) {
    return message;
  }

  return isOpen ? message : null;
};

export default Message;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Loader: ComponentMultiStyleConfig = {
  parts: ["container"],
  baseStyle: {
    container: {
      position: "relative",
    },
    overlay: {
      position: "absolute",
      inset: 0,
      background: "overlay-white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 200,
    },
  },
};
export default Loader;

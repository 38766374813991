import { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const drawerItemFocusStyle = {
  _focus: {
    boxShadow: "inset-outline",
    outline: 0,
    ".js-focus-visible &:not(.focus-visible)": {
      boxShadow: "none",
    },
    ".js-focus-visible &.focus-visible": {
      boxShadow: "inset-outline",
    },
  },
};

const ExpandMenu: ComponentMultiStyleConfig = {
  parts: ["drawerMenu", "drawerHeaderWrapper", "drawerHeader", "drawerItem", "drawerBody", "closeButton"],

  baseStyle: {
    drawerHeaderWrapper: {
      backgroundColor: "dpd-black",
      color: "white",
      alignItems: "center",
      justifyContent: "space-between",
      height: "3.5625rem",
      paddingLeft: "1.4rem",
      paddingRight: "0.4rem",
    },
    drawerBody: { padding: 0 },
    drawerHeader: { fontWeight: "light", fontSize: "1.25rem", padding: 0 },
    drawerMenu: {
      pointerEvents: "initial",
      boxShadow: "menu",
      maxWidth: "21.875rem",
      marginTop: { base: "3.625rem", md: "0" },
      _focus: {
        ".js-focus-visible &:not(.focus-visible)": {
          boxShadow: "menu",
        },
      },
    },

    drawerItem: {
      paddingY: "1.25rem",
      paddingX: { base: "1.375rem", md: "1.4rem" },
      fontSize: "1rem",
      display: "block",
      ...drawerItemFocusStyle,
      _active: { ...drawerItemFocusStyle },
    },
    drawerFooter: { paddingX: { base: "1.375rem", md: "1.25rem" }, paddingY: "1.25rem" },
    closeButton: { position: "relative", top: 0, right: 0, color: "white", fontSize: "0.625rem" },
  },
};

export const Drawer: ComponentMultiStyleConfig = {
  parts: ["dialogContainer"],
  baseStyle: {
    dialogContainer: { pointerEvents: responsiveStyle({ mobile: "none", desktop: "initial" }) },
  },
};

export const EXPAND_MENU_GUTTER = 8;

export default ExpandMenu;

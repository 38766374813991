import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const PasswordInput: ComponentMultiStyleConfig = {
  parts: ["indicatorContainer", "indicator", "strengthLabel"],
  baseStyle: {
    indicatorContainer: {
      height: "3px",
      position: "relative",
      flexGrow: 1,
      backgroundColor: "grey.light",
    },
    indicator: { position: "absolute", top: 0, bottom: 0, left: 0 },
    strengthLabel: {
      flexShrink: 0,
      flexBasis: "5rem",
    },
  },
};
export default PasswordInput;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const HorizontalOverflow: ComponentMultiStyleConfig = {
  parts: ["container", "scrollContainer", "shadows", "leftShadow", "rightShadow", "bothShadows"],
  baseStyle: {
    container: {
      position: "relative",
      background: "white",
    },
    scrollContainer: {
      padding: "0.1rem",
    },
    shadows: {
      position: "absolute",
      inset: 0,
      pointerEvents: "none",
      backgroundRepeat: "no-repeat",
    },
    leftShadow: {
      backgroundImage: "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
      backgroundPosition: "left center",
      backgroundSize: "40px 100%",
    },
    rightShadow: {
      backgroundImage: "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
      backgroundPosition: "right center",
      backgroundSize: "40px 100%",
    },
    bothShadows: {
      backgroundImage: `
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)),
      linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))
      `,
      backgroundPosition: "left center, right center",
      backgroundSize: "40px 100%, 40px 100%",
    },
  },
};
export default HorizontalOverflow;

import { ComponentType, useEffect } from "react";

import hoistNonReactStatics from "hoist-non-react-statics";
import { AppProps } from "next/app";

import LocaleContext from "../LocaleContext";
import { setLocaleState } from "../localeState";
import { TAppWithLocales } from "../types";

const appWithLocales = <Props extends AppProps = AppProps>(
  WrappedComponent: ComponentType<Props>
): TAppWithLocales<Props> =>
  hoistNonReactStatics((props: Props) => {
    const { customLocales, customLocale, _escapeMiddleware } = props.pageProps;

    if ((!customLocales || !customLocale) && !_escapeMiddleware) {
      throw new Error("No locales found in appWithLocales.");
    }

    useEffect(() => {
      setLocaleState(customLocales, customLocale);
      document.body.setAttribute("data-locale", customLocale);
    }, [customLocales, customLocale]);

    return (
      <LocaleContext.Provider value={{ customLocales, customLocale }}>
        <WrappedComponent {...props} />
      </LocaleContext.Provider>
    );
  }, WrappedComponent);

export default appWithLocales;

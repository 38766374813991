/* eslint-disable @typescript-eslint/naming-convention */

const envVariables = {
  APP_ENV: process.env.APP_ENV,
  NEXT_PUBLIC_DOMAIN_LT: process.env.NEXT_PUBLIC_DOMAIN_LT,
  NEXT_PUBLIC_DOMAIN_LV: process.env.NEXT_PUBLIC_DOMAIN_LV,
  NEXT_PUBLIC_DOMAIN_EE: process.env.NEXT_PUBLIC_DOMAIN_EE,
  NEXT_PUBLIC_LOCALHOST_AS_DOMAIN: process.env.NEXT_PUBLIC_LOCALHOST_AS_DOMAIN,
  NEXT_PUBLIC_USE_API_MOCKS: process.env.NEXT_PUBLIC_USE_API_MOCKS,
  NEXT_PUBLIC_BASE_API_URL_LT: process.env.NEXT_PUBLIC_BASE_API_URL_LT,
  NEXT_PUBLIC_BASE_API_URL_LV: process.env.NEXT_PUBLIC_BASE_API_URL_LV,
  NEXT_PUBLIC_BASE_API_URL_EE: process.env.NEXT_PUBLIC_BASE_API_URL_EE,
  NEXT_PUBLIC_DOMAIN_LOCALES_LT: process.env.NEXT_PUBLIC_DOMAIN_LOCALES_LT,
  NEXT_PUBLIC_DOMAIN_LOCALES_LV: process.env.NEXT_PUBLIC_DOMAIN_LOCALES_LV,
  NEXT_PUBLIC_DOMAIN_LOCALES_EE: process.env.NEXT_PUBLIC_DOMAIN_LOCALES_EE,
  NEXT_PUBLIC_BANNERS_LANDING_ZONE: process.env.NEXT_PUBLIC_BANNERS_LANDING_ZONE,
  NEXT_PUBLIC_BANNERS_FAQ_ZONE: process.env.NEXT_PUBLIC_BANNERS_FAQ_ZONE,
  NEXT_PUBLIC_BANNERS_LOGIN_ZONE: process.env.NEXT_PUBLIC_BANNERS_LOGIN_ZONE,
  NEXT_PUBLIC_BANNERS_REGISTER_ZONE: process.env.NEXT_PUBLIC_BANNERS_REGISTER_ZONE,
  NEXT_PUBLIC_BANNERS_FORGOT_PASSWORD_ZONE: process.env.NEXT_PUBLIC_BANNERS_FORGOT_PASSWORD_ZONE,
  NEXT_PUBLIC_BANNERS_RESET_PASSWORD_ZONE: process.env.NEXT_PUBLIC_BANNERS_RESET_PASSWORD_ZONE,
  NEXT_PUBLIC_NOTIFICATION_LANDING_ZONE: process.env.NEXT_PUBLIC_NOTIFICATION_LANDING_ZONE,
  NEXT_PUBLIC_NOTIFICATION_LOGIN_ZONE: process.env.NEXT_PUBLIC_NOTIFICATION_LOGIN_ZONE,
  NEXT_PUBLIC_NOTIFICATION_REGISTER_ZONE: process.env.NEXT_PUBLIC_NOTIFICATION_REGISTER_ZONE,
  NEXT_PUBLIC_NOTIFICATION_ORDER_ZONE: process.env.NEXT_PUBLIC_NOTIFICATION_ORDER_ZONE,
  NEXT_PUBLIC_USE_LOCAL_TRANSLATIONS: process.env.NEXT_PUBLIC_USE_LOCAL_TRANSLATIONS,
  NEXT_PUBLIC_USE_WEBCHAT: process.env.NEXT_PUBLIC_USE_WEBCHAT,
  NEXT_PUBLIC_RECAPTCHA_USE_ENTERPRISE: process.env.NEXT_PUBLIC_RECAPTCHA_USE_ENTERPRISE,
  NEXT_PUBLIC_RECAPTCHA_USE_NET: process.env.NEXT_PUBLIC_RECAPTCHA_USE_NET,
  NEXT_PUBLIC_RECAPTCHA_KEY_LT: process.env.NEXT_PUBLIC_RECAPTCHA_KEY_LT,
  NEXT_PUBLIC_RECAPTCHA_KEY_LV: process.env.NEXT_PUBLIC_RECAPTCHA_KEY_LV,
  NEXT_PUBLIC_RECAPTCHA_KEY_EE: process.env.NEXT_PUBLIC_RECAPTCHA_KEY_EE,
  NEXT_PUBLIC_FACEBOOK_APP_ID_LT: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID_LT,
  NEXT_PUBLIC_FACEBOOK_APP_ID_LV: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID_LV,
  NEXT_PUBLIC_FACEBOOK_APP_ID_EE: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID_EE,
  NEXT_PUBLIC_FACEBOOK_PIXEL_ID_LT: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_LT,
  NEXT_PUBLIC_FACEBOOK_PIXEL_ID_LV: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_LV,
  NEXT_PUBLIC_FACEBOOK_PIXEL_ID_EE: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_EE,
  NEXT_PUBLIC_CONTRACTED_USER_COOKIE_NAME: process.env.NEXT_PUBLIC_CONTRACTED_USER_COOKIE_NAME,
  NEXT_PUBLIC_HERE_AUTOCOMPLETE_API_URL: process.env.NEXT_PUBLIC_HERE_AUTOCOMPLETE_API_URL,
  NEXT_PUBLIC_HERE_LOOKUP_API_URL: process.env.NEXT_PUBLIC_HERE_LOOKUP_API_URL,
  NEXT_PUBLIC_HERE_API_KEY_LT: process.env.NEXT_PUBLIC_HERE_API_KEY_LT,
  NEXT_PUBLIC_HERE_API_KEY_LV: process.env.NEXT_PUBLIC_HERE_API_KEY_LV,
  NEXT_PUBLIC_HERE_API_KEY_EE: process.env.NEXT_PUBLIC_HERE_API_KEY_EE,
  NEXT_PUBLIC_HERE_MAPS_SCRIPT: process.env.NEXT_PUBLIC_HERE_MAPS_SCRIPT,
  NEXT_PUBLIC_HERE_MAPS_CSS: process.env.NEXT_PUBLIC_HERE_MAPS_CSS,
  NEXT_PUBLIC_USE_GTM: process.env.NEXT_PUBLIC_USE_GTM,
  CHAT_BOT_ID_LV: process.env.CHAT_BOT_ID_LV,
  CHAT_BOT_ID_LT: process.env.CHAT_BOT_ID_LT,
  CHAT_BOT_ID_EE: process.env.CHAT_BOT_ID_EE,
  CHAT_BOT_API_KEY: process.env.CHAT_BOT_API_KEY,
  ENCRYPT_SECRET_KEY: process.env.ENCRYPT_SECRET_KEY,
  HERE_API_OAUTH_TOKEN_URL: process.env.HERE_API_OAUTH_TOKEN_URL,
  HERE_API_OAUTH_TOKEN_ACCESS_KEY_LV: process.env.HERE_API_OAUTH_TOKEN_ACCESS_KEY_LV,
  HERE_API_OAUTH_TOKEN_SECRET_KEY_LV: process.env.HERE_API_OAUTH_TOKEN_SECRET_KEY_LV,
  HERE_API_OAUTH_TOKEN_ACCESS_KEY_LT: process.env.HERE_API_OAUTH_TOKEN_ACCESS_KEY_LT,
  HERE_API_OAUTH_TOKEN_SECRET_KEY_LT: process.env.HERE_API_OAUTH_TOKEN_SECRET_KEY_LT,
  HERE_API_OAUTH_TOKEN_ACCESS_KEY_EE: process.env.HERE_API_OAUTH_TOKEN_ACCESS_KEY_EE,
  HERE_API_OAUTH_TOKEN_SECRET_KEY_EE: process.env.HERE_API_OAUTH_TOKEN_SECRET_KEY_EE,
};

const getEnv = (name: string): string | undefined => envVariables[name];

export default getEnv;

import { FormikHelpers } from "formik";
import { useUserFeedbackFormInternalState } from "./useUserFeedbackFormInternalState";
import useData from "@hooks/useData";
import { TCreateUserFeedbackForm, TCreateUserFeedbackRequest } from "../types";
import { useUserFeedbackFormErrorMessage } from "./useUserFeedbackFormErrorMessage";
import { createUserFeedback } from "../services/createUserFeedback";
import mapRequestErrorsToForm from "../utils/mapRequestErrorsToForm";
import useRecaptcha from "@lib/recaptcha/useRecaptcha";
import { reportMessage } from "@utils/errorReporting";

type TUserFeedbackFormRequestArgs = {
  requestData: TCreateUserFeedbackRequest;
};

const useUserFeedbackForm = (onFormClose: () => void, onFormSuccess?: () => void) => {
  const handleError = useUserFeedbackFormErrorMessage();
  const handleRecaptcha = useRecaptcha("userFeedback");

  const { userFeedbackForm, setErrors, clearErrors } = useUserFeedbackFormInternalState();

  const fetcher = async (args?: TUserFeedbackFormRequestArgs) => {
    if (!args) {
      handleError();
      return false;
    }

    const recaptchaToken = await handleRecaptcha();

    if (!recaptchaToken) {
      reportMessage("Recaptcha token not available");
      handleError();
      return Promise.reject(false);
    }

    args.requestData.recaptcha = recaptchaToken;

    const response = await createUserFeedback(args.requestData);
    if (!response.isSuccess) {
      const errors = response.errorDetails ? mapRequestErrorsToForm(response.errorDetails) : undefined;

      document.getElementsByClassName("chakra-modal__content-container")[0]?.scroll?.(0, 0);
      setErrors(errors);
      handleError(response.errorMessage);
    }

    return response.isSuccess;
  };

  const { isLoading, handleFetch } = useData<boolean, TUserFeedbackFormRequestArgs>({
    fetcher,
  });

  const handleFeedbackSubmit = async (
    values: TCreateUserFeedbackForm,
    { resetForm }: FormikHelpers<TCreateUserFeedbackForm>
  ) => {
    const isSuccess = await handleFetch({ requestData: values });

    if (isSuccess) {
      resetForm();
      onFormSuccess?.();

      setTimeout(() => {
        onFormClose();
      }, 3000);
    }
  };

  return {
    ...userFeedbackForm,
    isLoading: isLoading,
    clearErrors,
    handleFeedbackSubmit,
  };
};

export default useUserFeedbackForm;

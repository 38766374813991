import { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const Order: ComponentMultiStyleConfig = {
  parts: ["orderSteps", "pageContent"],
  baseStyle: {
    orderSteps: {
      mt: responsiveStyle({ mobile: "-1rem", desktop: "0" }),
      mx: responsiveStyle({ mobile: "0.5rem", desktop: "0" }),
      width: responsiveStyle({ mobile: "auto", desktop: "14.5rem" }),
    },
    pageContent: {
      flex: 1,
      width: "100%",
      ml: responsiveStyle({ mobile: "0", desktop: "2rem" }),
      mb: responsiveStyle({ mobile: "0", desktop: "2.25rem" }),
    },
  },
};

export default Order;

import { extendTheme } from "@chakra-ui/react";

import breakpoints from "./breakpoints";
import colors from "./colors";
import components from "./components";
import fonts from "./fonts";
import shadows from "./shadows";

import "focus-visible/dist/focus-visible";

const theme = extendTheme({
  colors,
  fonts,
  shadows,
  components,
  breakpoints,
  styles: {
    global: {
      body: {
        color: "dpd-black",
        minWidth: "21.875rem",
      },
    },
  },
});

export default theme;

import { FC, useCallback, useState } from "react";

import { MessageContext, MessageActionContext } from "./context";
import { TMessageContext } from "./types";

const MessageContextProvider: FC<{ children?: any }> = ({ children }) => {
  const [internalMessage, setInternalMessage] = useState<TMessageContext>({ message: "", props: {}, messageNo: 0 });

  const setMessage = useCallback((message) => {
    setInternalMessage(({ messageNo }) => ({ ...message, messageNo: messageNo + 1 }));
  }, []);

  return (
    <MessageActionContext.Provider value={setMessage}>
      <MessageContext.Provider value={internalMessage}>{children}</MessageContext.Provider>
    </MessageActionContext.Provider>
  );
};
export default MessageContextProvider;

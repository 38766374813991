import { Component, Fragment, ReactNode } from "react";

import Router from "next/router";

import { ROUTES } from "@utils/constants";

interface IProps {
  children: ReactNode;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): Partial<IState> {
    return { hasError: true };
  }

  componentDidCatch(): void {
    Router.replace(ROUTES.INTERNAL_ERROR);
  }

  render(): ReactNode {
    return this.state.hasError ? <></> : this.props.children;
  }
}

export default ErrorBoundary;

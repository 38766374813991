const I18NextHttpBackend = require("i18next-http-backend/cjs");

module.exports = {
  i18n: {
    defaultLocale: "default",
    locales: ["en", "lt", "lv", "ee", "ru", "default"],
    localeDetection: false,
  },
  serializeConfig: false,
  use: [I18NextHttpBackend],
  react: {
    useSuspense: false,
  },
};
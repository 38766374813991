type TWrappedFunction = (...args: any[]) => any;

interface IDebouncedFunction<F extends TWrappedFunction> {
  (...args: Parameters<F>): Promise<ReturnType<F>>;
}

const debounce = <T extends TWrappedFunction>(func: T, delay = 500): IDebouncedFunction<T> => {
  let timeout: number | null = null;
  return (...args) =>
    new Promise((resolve) => {
      if (timeout !== null) {
        window?.clearTimeout(timeout);
      }
      timeout = window?.setTimeout(() => {
        resolve(func(...args));
        timeout = null;
      }, delay);
    });
};

export default debounce;

import { i18n } from "next-i18next";
import { AnySchema } from "yup";

import { OrderFields } from "../../constants";

const packageInfoRequiredFields = [
  OrderFields.STARTING_COUNTRY,
  OrderFields.DESTINATION_COUNTRY,
  OrderFields.PACKAGE_SIZE,
  OrderFields.DELIVERY_METHOD,
];

const getPackageInfoRequiredFieldSchema = (schema: AnySchema, fieldName: OrderFields): AnySchema =>
  packageInfoRequiredFields.includes(fieldName) ? schema.required(i18n?.t("order-message-field_is_required")) : schema;

export { getPackageInfoRequiredFieldSchema };

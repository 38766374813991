type TLocales = {
  locales: string[];
  locale: string;
};

let promiseResolver: ((value: TLocales | PromiseLike<TLocales>) => void) | null | undefined;

const localePromise = new Promise<{ locales: string[]; locale: string }>((resolve) => {
  promiseResolver = resolve;
});

export const setLocaleState = (locales: string[], locale: string): void => {
  if (!promiseResolver) {
    return;
  }

  promiseResolver({ locales, locale });
  promiseResolver = null;
};

export const getLocaleState = (): Promise<{ locales: string[]; locale: string }> => {
  if (typeof window === "undefined") {
    throw new Error("getLocaleState can only be called in client side");
  }
  return localePromise;
};

import detectBrowser from "browser-detect";

import { checkFeatures } from "./checkFeatures";

export const checkBrowserFeatures = (): boolean => {
  const currentBowser = detectBrowser();

  // If no browser name was detected, this function might be running in node env.
  if (!currentBowser.name) {
    return true;
  }

  //  check required app features
  return checkFeatures();
};

import { AxiosResponse, AxiosRequestConfig } from "axios";

import * as https from "https";

import initApiMocks from "@helpers/mocks";
import { getLocaleState } from "@lib/custom-locales";
import { manageToken } from "@modules/auth/services/tokenCookieManager";
import { HEADERS } from "@utils/constants";
import getEnv from "@utils/getEnv";
import getHostName from "@utils/getHostName";

import axios from "./axiosInstance";
import getBaseApiUrl from "./getBaseApiUrl";
import { TFetchApiParams, TFetchError } from "./types";

const FIXED_HEADERS = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Source: "frontendApplication",
};

// Lazy Load api mocks

const getBrowserHostname = () => (typeof window !== "undefined" ? window.location.host : undefined);

type TAdditionalHeaders = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Authorization: string;
};

const fetchApi = async <T = any, K = any>({
  url,
  method = "GET",
  baseURL,
  params,
  data,
  headers,
  locale: customLocale,
  hostname = getBrowserHostname(),
}: TFetchApiParams<K>): Promise<AxiosResponse<T, K>> => {
  if (!hostname) {
    throw new Error(`Hostname is not available for request: ${url}`);
  }
  const apiBaseUrl = baseURL || getBaseApiUrl(getHostName(hostname));
  const additionalHeaders = {} as TAdditionalHeaders;
  const token = await manageToken(hostname, apiBaseUrl);
  additionalHeaders.Authorization = `Bearer ${token}`;

  if (!apiBaseUrl) {
    throw new Error(`Base API url not available for request: ${url}`);
  }

  if (!customLocale && typeof window === "undefined") {
    throw new Error("locale parameter must be provided explicitly when calling fetchApi on server side");
  }

  if (getEnv("NEXT_PUBLIC_USE_API_MOCKS") === "true") {
    await initApiMocks();
  }

  const locale = !customLocale ? (await getLocaleState())?.locale : customLocale;

  if (!locale) {
    throw new Error(`Locale is not available for request: ${url}`);
  }

  const axiosRequestConfig = {
    url,
    baseURL: apiBaseUrl,
    method,
    params,
    data,
    headers: { ...FIXED_HEADERS, [HEADERS.LANGUAGE]: locale, ...headers, ...additionalHeaders },
  } as AxiosRequestConfig<K>;

  if (getEnv("APP_ENV") === "local") {
    axiosRequestConfig.httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    });
  }

  const response: AxiosResponse<T, K> = await axios(axiosRequestConfig);

  return response;
};
export const isFetchError = <T = any, K = any>(error: unknown): error is TFetchError<T, K> => {
  const fetchError = error as TFetchError<T, K>;
  return !!fetchError?.isAxiosError;
};

export default fetchApi;

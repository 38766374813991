import type { ComponentMultiStyleConfig, CSSObject } from "@chakra-ui/react";

const stepCommon: CSSObject = {
  width: "2rem",
  height: "2rem",
  border: "1px solid",
  borderRadius: "50%",
  fontSize: "0.875rem",
  fontWeight: 400,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Steps: ComponentMultiStyleConfig = {
  parts: ["step", "stepActive", "divider", "flexDivider"],
  baseStyle: {
    step: {
      ...stepCommon,
      backgroundColor: "white",
      borderColor: "red.lead",
      color: "black",
      flexShrink: 0,
    },
    stepActive: {
      ...stepCommon,
      backgroundColor: "red.lead",
      borderColor: "red.lead",
      color: "white",
      flexShrink: 0,
    },
    divider: {
      height: "1.875rem",
      borderLeft: "1px solid",
      borderColor: "red.lead",
      marginLeft: "0.9375rem",
    },
    flexDivider: {
      marginTop: "0.9375rem",
      borderTop: "1px solid",
      borderColor: "red.lead",
      width: "100%",
    },
  },
};

export default Steps;

import { TFetchError } from "@lib/api-fetcher";
import { TErrorResponse } from "@utils/types";

import { TApiErrorResponse } from "../types";

/**
 * @K request data validation messages are being returned as error response data
 */
const getApiErrorResponse = <K>(
  err: TFetchError<TErrorResponse<Record<keyof K, string | undefined>>, K>
): TApiErrorResponse<K> => {
  if (!err.response) {
    return { errorMessage: undefined, errorDetails: undefined };
  }
  const errData = err.response.data;
  const errorMessage = errData?.title;
  const errorDetails = errData?.detail;
  return { errorMessage, errorDetails };
};
export default getApiErrorResponse;

import { FC } from "react";

import { Global } from "@emotion/react";

const Fonts: FC = () => (
  <Global
    styles={`
      @font-face {
        font-family: "DPD Pluto Sans";
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url("/assets/fonts/PlutoSansDPDThin-Web.woff") format("woff");
      }
      
      @font-face {
        font-family: "DPD Pluto Sans";
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url("/assets/fonts/PlutoSansDPDExtraLight-Web.woff") format("woff");
      }
      
      @font-face {
        font-family: "DPD Pluto Sans";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url("/assets/fonts/PlutoSansDPDLight-Web.woff") format("woff");
      }
      
      @font-face {
        font-family: "DPD Pluto Sans";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url("/assets/fonts/PlutoSansDPDRegular-Web.woff") format("woff");
      }
    `}
  />
);

export default Fonts;

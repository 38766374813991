import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { fetchApi, isFetchError } from "@lib/api-fetcher";
import { TFetchApiParams } from "@lib/api-fetcher/types";
import { reportError } from "@utils/errorReporting";

import { TServiceConfig, TService } from "./types";
import getApiErrorResponse from "./utils/getApiErrorResponse";
import getErrStatusList from "./utils/getErrStatusList";

const parseUrlEntries = (
  params: Record<string | symbol | number, string | string[] | undefined> | URLSearchParams | undefined
): [string, string][] | undefined => {
  if (!params) {
    return undefined;
  }
  if (params instanceof URLSearchParams) {
    return Array.from(params.entries());
  }
  const entries: [string, string][] = Object.entries(params).reduce<[string, string][]>((acc, entry) => {
    if (Array.isArray(entry[1])) {
      entry[1].forEach((val) => {
        acc.push([entry[0], val]);
      });
      return acc;
    }

    acc.push(entry as [string, string]);
    return acc;
  }, []);

  return entries;
};

const mergeUrlParams = (
  firstParams: Record<string | symbol | number, string | string[] | undefined> | URLSearchParams | undefined,
  secondParams: Record<string | symbol | number, string | string[] | undefined> | URLSearchParams | undefined
): Record<string | symbol | number, string | string[] | undefined> | URLSearchParams => {
  if (!(firstParams instanceof URLSearchParams) && !(secondParams instanceof URLSearchParams)) {
    return { ...firstParams, ...secondParams };
  }
  let entries: [string, string][] = [];
  const firstEntries = parseUrlEntries(firstParams);
  const secondEntries = parseUrlEntries(secondParams);
  if (firstEntries) {
    entries = [...entries, ...firstEntries];
  }
  if (secondEntries) {
    entries = [...entries, ...secondEntries];
  }
  const params = new URLSearchParams();
  entries.forEach(([key, value]) => {
    params.append(key, value);
  });

  return params;
};

const service = async <T, K>(
  requestConfig: TFetchApiParams<K> & AxiosRequestConfig<K>,
  isExternalApi: boolean
): Promise<AxiosResponse<T, K>> => (isExternalApi ? axios(requestConfig) : fetchApi(requestConfig));
/**
 * @T response data (on success)
 * @K request data (if any)
 */
const createApiService = <T = any, K = any>({
  url,
  method,
  params: serviceParams,
  headers: serviceHeaders,
  baseURL,
  allowedErrStatus,
  isExternalApi = false,
}: TServiceConfig): TService<T, K> => {
  const errStatusList = getErrStatusList(allowedErrStatus);

  return async ({ params, data, headers, url: dynamicUrl, method: dynamicMethod } = {}) => {
    try {
      if (!dynamicUrl && !url) {
        return { isSuccess: false, error: new Error("Service url not found") };
      }

      if (!dynamicMethod && !method) {
        return { isSuccess: false, error: new Error("Service method not found") };
      }
      const requestConfig: TFetchApiParams<K> & AxiosRequestConfig<K> = {
        url: (dynamicUrl || url)!,
        method: (dynamicMethod || method)!,
        params: mergeUrlParams(serviceParams, params),
        headers: { ...serviceHeaders, ...headers } as any,
        baseURL,
        data,
      };

      const response = await service<T, K>(requestConfig, isExternalApi);

      return { isSuccess: true, data: response.data };
    } catch (error) {
      reportError(error, { lib: "createApiService" });
      if (isFetchError<T, K>(error) && error.response?.status && errStatusList.includes(error.response.status)) {
        return {
          isSuccess: false,
          error: error as Error,
          statusCode: error.response?.status,
          ...getApiErrorResponse<K>(error as any),
        };
      }
      if (isFetchError<T, K>(error)) {
        return { isSuccess: false, error: error as Error, statusCode: error.response?.status };
      }
      return { isSuccess: false, error: error as Error, statusCode: null };
    }
  };
};

export default createApiService;

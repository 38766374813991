import getEnv from "@utils/getEnv";

const getHostName = (host: string): string => {
  if (!getEnv("NEXT_PUBLIC_LOCALHOST_AS_DOMAIN")) {
    return host;
  }
  if (!host || host === "" || host === "localhost:3000") {
    return getEnv("NEXT_PUBLIC_LOCALHOST_AS_DOMAIN") || "";
  }
  return host;
};

export default getHostName;

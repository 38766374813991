import { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

import * as https from "https";

import axios from "@lib/api-fetcher/axiosInstance";
import { decryptString } from "@lib/encryptor/encryptor";
import { API_ROUTES } from "@utils/constants";
import { reportError } from "@utils/errorReporting";
import getEnv from "@utils/getEnv";

const ADHOCK_JWT_COOKIE = "adhock_jwt";

export const manageToken = async (hostname: string | undefined, apiBaseUrl: string | null): Promise<string> => {
  let token;
  if (typeof window === "undefined") {
    if (apiBaseUrl === null) {
      throw new Error("Api base url empty");
    }
    try {
      const axiosRequestConfig = { url: API_ROUTES.ADHOCK_TOKEN, baseURL: apiBaseUrl } as AxiosRequestConfig;
      if (getEnv("APP_ENV") === "local") {
        axiosRequestConfig.httpsAgent = new https.Agent({
          rejectUnauthorized: false,
        });
      }
      token = await axios(axiosRequestConfig);
      if (token?.status === 200) {
        return token.data.token;
      }
    } catch (err) {
      reportError(err, { module: "token" });
      return "";
    }
  }
  const tokenCookie = Cookies.get(ADHOCK_JWT_COOKIE);
  if (!tokenCookie) {
    token = await axios({ url: "/api/csrf-token" });
    const decryptedToken = decryptString(token.data);
    Cookies.set(ADHOCK_JWT_COOKIE, decryptedToken, {
      sameSite: "strict",
    });
    return decryptedToken;
  }

  return tokenCookie;
};

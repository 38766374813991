import { FC, useCallback, useMemo } from "react";

import Router, { useRouter } from "next/router";

import { ROUTES } from "@utils/constants";

import AuthContext from "./contexts/AuthContext";
import useAuthData from "./hooks/useAuthData";
import useAuthInterceptors from "./hooks/useAuthInterceptors";
import { TAuthProviderProps } from "./types";

const AuthProvider: FC<TAuthProviderProps> = ({ hasAuthCookie, children }) => {
  const [authData, setAuthData] = useAuthData();
  const router = useRouter();

  useAuthInterceptors(authData, setAuthData);

  const logout = useCallback(() => {
    setAuthData(null);
    Router.push(ROUTES.LANDING);
  }, [setAuthData]);

  // On SSR we are just checking if cookie exists in request
  const value = useMemo(
    () => ({
      isLoggedIn: typeof window !== "undefined" ? !!authData : !!hasAuthCookie,
      setAuthData,
      logout,
    }),
    [authData, hasAuthCookie, logout, setAuthData]
  );

    const tabKey = router.query.tabKey as string;
    if (authData?.isPasswordExpired && tabKey !== "account") {
        Router.push(ROUTES.PROFILE_ACCOUNT);
    }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

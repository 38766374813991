import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import EmailInput from "@components/EmailInput";
import StarRating from "@components/StarRating";
import { FormMessage, Typography } from "@components/common";
import { CREATE_USER_FEEDBACK_FORM_FIELDS } from "@modules/userFeedback/utils/constants";
import { Field, Form, useFormikContext } from "formik";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "next-i18next";
import { IconButton } from "@components/buttons";
import useFormErrors from "@hooks/useFormErrors";
import { TCreateUserFeedbackForm } from "@modules/userFeedback/types";
import { Textarea } from "@components/TextInput";

type TUserFeedbackFormFieldsProps = {
  screenshot?: string | null;
  setIsInScreenshotMode: Dispatch<SetStateAction<boolean>>;
  apiResponseErrors: Partial<Record<keyof TCreateUserFeedbackForm, string | undefined>> | undefined;
  setCursorPosition: Dispatch<SetStateAction<{ x: number; y: number } | null>>;
};

const UserFeedbackFormFields: FC<TUserFeedbackFormFieldsProps> = ({
  screenshot,
  setIsInScreenshotMode,
  apiResponseErrors,
  setCursorPosition
}) => {
  const { t } = useTranslation();
  const { errors, setFieldValue, touched } = useFormikContext();

  useFormErrors(apiResponseErrors);

  return (
    <Form>
      <VStack spacing="1.375rem" alignItems="stretch">
        <VStack alignItems="flex-start" gap={1}>
          <Typography type="small">{t("user-feedback-rating-question")}</Typography>
          <Field name={CREATE_USER_FEEDBACK_FORM_FIELDS.RATING}>
            {() => <StarRating name={CREATE_USER_FEEDBACK_FORM_FIELDS.RATING} count={5} />}
          </Field>

          {errors[CREATE_USER_FEEDBACK_FORM_FIELDS.RATING] && touched[CREATE_USER_FEEDBACK_FORM_FIELDS.RATING] && (
            <FormMessage message={errors[CREATE_USER_FEEDBACK_FORM_FIELDS.RATING]} hasNoSpacing isInvalid />
          )}
        </VStack>

        <Textarea
          name={CREATE_USER_FEEDBACK_FORM_FIELDS.DESCRIPTION}
          label={t("user-feedback-form-field-description")}
          id="user_feedback_form-description-input"
          maxLength={500}
        />

        <EmailInput
          name={CREATE_USER_FEEDBACK_FORM_FIELDS.EMAIL}
          label={t("user-feedback-form-field-email")}
          id="user_feedback_form-email-input"
        />

        <Flex direction="column" gap={2}>
          <Text fontSize="sm" color="grey.mid">
            {t("user-feedback-form-field-file")}
          </Text>

          {errors[CREATE_USER_FEEDBACK_FORM_FIELDS.FILE] && touched[CREATE_USER_FEEDBACK_FORM_FIELDS.FILE] && (
            <FormMessage message={errors[CREATE_USER_FEEDBACK_FORM_FIELDS.FILE]} hasNoSpacing isInvalid />
          )}

          {!screenshot && (
            <IconButton
              aria-label="Add screenshot"
              onClick={(event) => {
                const cursorX = event.clientX;
                const cursorY = event.clientY;
                setCursorPosition({ x: cursorX, y: cursorY });
                setIsInScreenshotMode(true);
              }}
              icon={<AddIcon />}
              variant="dark"
              size="sm"
            />
          )}

          {screenshot && (
            <Flex align="center" gap={2}>
              <Image src={screenshot} alt="Screenshot" w={250} h="auto" objectFit="contain" maxH={300} />

              <IconButton
                aria-label="Delete screenshot"
                onClick={() => setFieldValue(CREATE_USER_FEEDBACK_FORM_FIELDS.FILE, "", false)}
                icon={<DeleteIcon color="red.lead" />}
                variant="transparent"
                size="sm"
              />
            </Flex>
          )}
        </Flex>
      </VStack>
    </Form>
  );
};

export default UserFeedbackFormFields;

import Badge from "@components/Badge/theme";
import Button from "@components/buttons/theme";
import Card from "@components/Card/theme";
import Carousel from "@components/Carousel/theme";
import Checkbox from "@components/Checkbox/theme";
import Common from "@components/common/theme";
import DataTable from "@components/DataTable/theme";
import DatePicker from "@components/DatePicker/theme";
import Dropdown from "@components/Dropdown/theme";
import Expand from "@components/Expand/theme";
import ExpandMenu, { Drawer } from "@components/ExpandMenu/theme";
import HorizontalOverflow from "@components/HorizontalOverflow/theme";
import LanguageSelect from "@components/LanguageSelect/theme";
import Link from "@components/Link/theme";
import Loader from "@components/Loader/theme";
import Message from "@components/Message/theme";
import Modal from "@components/Modal/theme";
import OptionButton from "@components/OptionButton/theme";
import PageHeader from "@components/PageHeader/theme";
import PasswordInput from "@components/PasswordInput/theme";
import PhoneInput from "@components/PhoneInput/theme";
import Popover from "@components/Popover/theme";
import Radio from "@components/Radio/theme";
import Select from "@components/Select/theme";
import Spinner from "@components/Spinner/theme";
import Steps from "@components/Steps/theme";
import SubmitInput from "@components/SubmitInput/theme";
import Tabs from "@components/Tabs/theme";
import TextInput from "@components/TextInput/theme";
import TimePicker from "@components/TimePicker/theme";
import Faq from "@modules/faq/theme";
import ActionCard from "@modules/LandingActions/components/ActionCard/theme";
import Footer from "@modules/layout/components/Footer/theme";
import Login from "@modules/login/theme";
import Order from "@modules/order/theme";

const components = {
  Button,
  TextInput,
  Checkbox,
  Common,
  Link,
  Radio,
  PhoneInput,
  Steps,
  Dropdown,
  SubmitInput,
  OptionButton,
  Drawer,
  Select,
  Message,
  DatePicker,
  TimePicker,
  Modal,
  LanguageSelect,
  Tabs,
  Badge,
  Carousel,
  ExpandMenu,
  HorizontalOverflow,
  Expand,
  DataTable,
  Spinner,
  Popover,
  Card,
  Loader,
  Faq,
  Footer,
  ActionCard,
  PasswordInput,
  PageHeader,
  Login,
  Order,
};

export default components;

let initializePromise: Promise<void> | null;

const initApiMocks = async (): Promise<void> => {
  if (initializePromise) {
    await initializePromise;
    return;
  }

  let promiseResolver;
  initializePromise = new Promise((resolve) => {
    promiseResolver = resolve;
  });
  if (typeof window === "undefined") {
    const { default: initServer } = await import("./server");
    initServer().listen();
  } else {
    const { default: initWorker } = await import("./browser");
    await initWorker().start({ onUnhandledRequest: "bypass" });
  }
  promiseResolver();
};
export default initApiMocks;

import { i18n } from "next-i18next";
import * as yup from "yup";
import type { AnySchema, StringSchema, Asserts } from "yup";

import { Router } from "@lib/router";

import {
  OrderStep,
  DeliveryMethod,
  OrderFields,
  InvoiceType,
  LabelPrint,
  ORDER_FIELDS_MAX_LENGTHS,
} from "../constants";
import { getPackageInfoRequiredFieldSchema } from "./order/packageInfo";
import { getReceiverRequiredFieldSchema } from "./order/receiver";
import { getSenderRequiredFieldSchema } from "./order/sender";
import { getShipmentRequiredFieldSchema } from "./order/shipment";
import { getSummaryRequiredFieldSchema, getTermsAgreementRequiredSchema } from "./order/summary";

const addMaxNameLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.name, i18n?.t("order-message-name_exceeds_maximum_length")));

const addMaxLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.default, i18n?.t("order-message-exceeds_maximum_length")));

const addMaxLengthLong = <T extends string | null | undefined>(schema: StringSchema<T>) =>
    yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.long, i18n?.t("order-message-exceeds_maximum_length")));

const addMaxStreetLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.street, i18n?.t("order-message-street_exceeds_maximum_length")));

const addMaxEmailLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.email, i18n?.t("order-message-email_exceeds_maximum_length")));

const addMaxCommentLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.comment, i18n?.t("order-message-comment_exceeds_maximum_length")));

const addMaxSenderCommentLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() =>
    schema.max(ORDER_FIELDS_MAX_LENGTHS.senderComment, i18n?.t("order-message-comment_exceeds_maximum_length"))
  );

const addMaxCityLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.city, i18n?.t("order-message-city_exceeds_maximum_length")));

const addMaxHouseNoLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() =>
    schema.max(ORDER_FIELDS_MAX_LENGTHS.houseNo, i18n?.t("order-message-street_no_exceeds_maximum_length"))
  );

const addMaxFlatNoLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.flatNo, i18n?.t("order-message-flat_no_exceeds_maximum_length")));

const addMaxZipCodeLength = <T extends string | null | undefined>(schema: StringSchema<T>) =>
  yup.lazy(() => schema.max(ORDER_FIELDS_MAX_LENGTHS.zip, i18n?.t("order-message-zip_exceeds_maximum_length")));

function getLocaleBasedRequiredFieldSchema(validationSchema: yup.AnySchema<any, any, any>, fieldName: OrderFields) {
  const isLocaleBasedRequiredField =
    (Router.defaultLocale === "lt" || Router.defaultLocale === "lv") && fieldName === OrderFields.RECEIVER_EMAIL;

  if (isLocaleBasedRequiredField) {
    const schema = validationSchema.when(
      OrderFields.IS_INTRA_EUROPE,
      ([isIntraEurope], intraEuropeSchema: AnySchema) =>
        isIntraEurope ? intraEuropeSchema.required(i18n?.t("order-message-field_is_required")) : intraEuropeSchema
    );
    return schema;
  }

  return validationSchema;
}

const getRequiredRuleByStepAndDeliveryMethod =
  (fieldName: OrderFields) => ([currentStep, deliveryMethod], schema: AnySchema) =>
    yup.lazy(() => {
      let validationSchema = getPackageInfoRequiredFieldSchema(schema, fieldName);

      if (currentStep === OrderStep.PackageInfo) {
        return validationSchema;
      }

      if (deliveryMethod === DeliveryMethod.PudoToPudo || deliveryMethod === DeliveryMethod.PudoToDoor) {
        validationSchema = getShipmentRequiredFieldSchema(validationSchema, fieldName, deliveryMethod);
        validationSchema = getLocaleBasedRequiredFieldSchema(validationSchema, fieldName);
        if (currentStep === OrderStep.ShipmentDetails) {
          return validationSchema;
        }
      }
      if (deliveryMethod === DeliveryMethod.DoorToDoor || deliveryMethod === DeliveryMethod.DoorToPudo) {
        validationSchema = getSenderRequiredFieldSchema(validationSchema, fieldName, deliveryMethod);
        if (currentStep === OrderStep.SenderDetails) {

          return validationSchema;
        }

        validationSchema = getReceiverRequiredFieldSchema(validationSchema, fieldName, deliveryMethod);
        validationSchema = getLocaleBasedRequiredFieldSchema(validationSchema, fieldName);
        if (currentStep === OrderStep.ReceiverDetails) {
          return validationSchema;
        }
      }

      validationSchema = getSummaryRequiredFieldSchema(validationSchema, fieldName);
      return validationSchema;
    });

const getNullableStringFieldSchema = (fieldName: OrderFields): yup.StringSchema<string | null | undefined> =>
  yup
    .string()
    .nullable()
    .when(
      [OrderFields.CURRENT_STEP, OrderFields.DELIVERY_METHOD],
      // yup typing issue https://github.com/jquense/yup/issues/1417
      getRequiredRuleByStepAndDeliveryMethod(fieldName) as any
    );

// Method to validation conditionaly create new receiver block while creating
// new parcel
const getNullableNewReceiverSchema = (fieldName: OrderFields): yup.StringSchema<string | null | undefined> =>
  yup
    .string()
    .nullable()
    .when([OrderFields.CURRENT_STEP, OrderFields.DELIVERY_METHOD, OrderFields.HAS_NEW_ADDRESS], ((
      [
          currentStep,
          deliveryMethod,
          isNewAddress
      ]
    ) => {

      if (isNewAddress && [OrderStep.ShipmentDetails, OrderStep.ReceiverDetails].includes(currentStep)) {
        const isRequiredMessage = i18n?.t("order-message-field_is_required");

        const addressFields = [
          OrderFields.NEW_ADDRESS_STREET,
          OrderFields.NEW_ADDRESS_CITY,
          OrderFields.NEW_ADDRESS_ZIP_CODE,
        ];

        if (DeliveryMethod.PudoToPudo === deliveryMethod && addressFields.includes(fieldName)) {
          return yup.string().optional();
        }

        if (DeliveryMethod.DoorToPudo === deliveryMethod && addressFields.includes(fieldName)) {
          return yup.string().optional();
        }

        if (deliveryMethod === DeliveryMethod.PudoToDoor) {
          return yup.string().required(isRequiredMessage);
        }

        if (
          deliveryMethod === DeliveryMethod.DoorToDoor &&
          [OrderFields.NEW_ADDRESS_LOCKER, OrderFields.NEW_ADDRESS_LOCKER_NAME].includes(fieldName)
        ) {
          return yup.string().optional();
        }

        return yup.string().required(isRequiredMessage);
      }

      return yup.string().optional();
    }) as any);

export const orderFormNewReceiverSchema = {
  [OrderFields.HAS_NEW_ADDRESS]: yup.bool(),

  [OrderFields.NEW_ADDRESS_NAME]: getNullableNewReceiverSchema(OrderFields.NEW_ADDRESS_NAME),
  [OrderFields.NEW_ADDRESS_PHONE]: getNullableNewReceiverSchema(OrderFields.NEW_ADDRESS_PHONE),
  [OrderFields.NEW_ADDRESS_EMAIL]: yup.string().nullable(),
  [OrderFields.NEW_ADDRESS_LOCKER]: getNullableNewReceiverSchema(OrderFields.NEW_ADDRESS_LOCKER),
  [OrderFields.NEW_ADDRESS_LOCKER_NAME]: getNullableNewReceiverSchema(OrderFields.NEW_ADDRESS_LOCKER_NAME),

  [OrderFields.NEW_ADDRESS_STREET]: getNullableNewReceiverSchema(OrderFields.NEW_ADDRESS_STREET),
  [OrderFields.NEW_ADDRESS_HOUSE_NO]: yup.string().nullable(),
  [OrderFields.NEW_ADDRESS_FLAT_NO]: yup.string().nullable(),
  [OrderFields.NEW_ADDRESS_CITY]: getNullableNewReceiverSchema(OrderFields.NEW_ADDRESS_CITY),
  [OrderFields.NEW_ADDRESS_ZIP_CODE]: getNullableNewReceiverSchema(OrderFields.NEW_ADDRESS_ZIP_CODE),
};

export const orderFormSchema = yup.object({
  ...orderFormNewReceiverSchema,

  [OrderFields.IS_DOMESTIC]: yup.bool().defined(),
  [OrderFields.IS_INTRA_EUROPE]: yup.bool().defined(),
  [OrderFields.IS_FROM_ORIGIN]: yup.bool().defined(),
  [OrderFields.CURRENT_STEP]: yup.string().oneOf(Object.values(OrderStep)).required(),
  [OrderFields.PROMO_CODE]: yup.string().optional(),
  [OrderFields.OFFER_GROUP_ID]: yup.string().nullable().optional(),
  [OrderFields.SHIPMENT_ID]: yup.string().nullable().optional(),
  [OrderFields.SHIPMENT_SIGNED_IN]: yup.bool().optional(),
  [OrderFields.AFFILIATE_ID]: yup.string().nullable().optional(),
  [OrderFields.AFFILIATE_RETURN_URL]: yup.string().nullable().optional(),

  [OrderFields.STARTING_COUNTRY]: getNullableStringFieldSchema(OrderFields.STARTING_COUNTRY),
  [OrderFields.STARTING_COUNTRY_NAME]: getNullableStringFieldSchema(OrderFields.STARTING_COUNTRY_NAME),
  [OrderFields.DESTINATION_COUNTRY]: getNullableStringFieldSchema(OrderFields.DESTINATION_COUNTRY),
  [OrderFields.DESTINATION_COUNTRY_NAME]: getNullableStringFieldSchema(OrderFields.DESTINATION_COUNTRY_NAME),
  [OrderFields.PACKAGE_SIZE]: yup
    .string()
    .nullable()
    .when(
      [OrderFields.CURRENT_STEP, OrderFields.DELIVERY_METHOD],
      // yup typing issue https://github.com/jquense/yup/issues/1417
      getRequiredRuleByStepAndDeliveryMethod(OrderFields.DELIVERY_METHOD) as any
    ),
  [OrderFields.DELIVERY_METHOD]: yup
    .string()
    .nullable()
    // Need to pass null explicitly https://github.com/jquense/yup/issues/104
    .oneOf([null, ...Object.values(DeliveryMethod)])
    // Exception to prevent cyclic dependency
    .when(OrderFields.CURRENT_STEP, ([currentStep], schema: AnySchema) =>
      getRequiredRuleByStepAndDeliveryMethod(OrderFields.DELIVERY_METHOD)([currentStep, null], schema)
    ),
  [OrderFields.SENDER_NAME]: addMaxNameLength(getNullableStringFieldSchema(OrderFields.SENDER_NAME)),
  [OrderFields.SENDER_PHONE_NUMBER]: getNullableStringFieldSchema(OrderFields.SENDER_PHONE_NUMBER),
  [OrderFields.SENDER_EMAIL]: addMaxEmailLength(getNullableStringFieldSchema(OrderFields.SENDER_EMAIL)),
  [OrderFields.RETURN_LOCKER]: getNullableStringFieldSchema(OrderFields.RETURN_LOCKER),
  [OrderFields.RETURN_LOCKER_NAME]: getNullableStringFieldSchema(OrderFields.RETURN_LOCKER_NAME),
  [OrderFields.SENDER_STREET]: addMaxStreetLength(getNullableStringFieldSchema(OrderFields.SENDER_STREET)),
  [OrderFields.SENDER_HOUSE_NO]: addMaxHouseNoLength(getNullableStringFieldSchema(OrderFields.SENDER_HOUSE_NO)),
  [OrderFields.SENDER_FLAT_NO]: addMaxFlatNoLength(getNullableStringFieldSchema(OrderFields.SENDER_FLAT_NO)),
  [OrderFields.SENDER_CITY]: addMaxCityLength(getNullableStringFieldSchema(OrderFields.SENDER_CITY)),
  [OrderFields.SENDER_ZIP_CODE]: addMaxZipCodeLength(getNullableStringFieldSchema(OrderFields.SENDER_ZIP_CODE)),
  [OrderFields.SENDER_COMMENTS]: addMaxSenderCommentLength(yup.string().nullable().optional()),
  [OrderFields.LABEL_PRINT]: getNullableStringFieldSchema(OrderFields.LABEL_PRINT)
    .nullable()
    // Need to pass null and "" explicitly https://github.com/jquense/yup/issues/104
    .oneOf([null, "", ...Object.values(LabelPrint)])
    .optional(),
  [OrderFields.PICKUP_DATE]: getNullableStringFieldSchema(OrderFields.PICKUP_DATE),
  [OrderFields.PICKUP_TIME_FROM]: getNullableStringFieldSchema(OrderFields.PICKUP_TIME_FROM),
  [OrderFields.PICKUP_TIME_TILL]: getNullableStringFieldSchema(OrderFields.PICKUP_TIME_TILL),
  [OrderFields.SENDER_AS_CONTACT_PERSON]: yup.bool().required(),
  [OrderFields.CONTACT_PERSON_NAME]: addMaxNameLength(getNullableStringFieldSchema(OrderFields.CONTACT_PERSON_NAME)),
  [OrderFields.CONTACT_PERSON_PHONE_NUMBER]: getNullableStringFieldSchema(OrderFields.CONTACT_PERSON_PHONE_NUMBER),
  [OrderFields.CONTACT_PERSON_EMAIL]: addMaxEmailLength(getNullableStringFieldSchema(OrderFields.CONTACT_PERSON_EMAIL)),

  [OrderFields.RECEIVER_NAME]: addMaxNameLength(getNullableStringFieldSchema(OrderFields.RECEIVER_NAME)),
  [OrderFields.RECEIVER_PHONE_NUMBER]: getNullableStringFieldSchema(OrderFields.RECEIVER_PHONE_NUMBER),
  [OrderFields.RECEIVER_EMAIL]: addMaxEmailLength(getNullableStringFieldSchema(OrderFields.RECEIVER_EMAIL)),
  [OrderFields.RECEIVER_LOCKER]: getNullableStringFieldSchema(OrderFields.RECEIVER_LOCKER),
  [OrderFields.RECEIVER_LOCKER_NAME]: getNullableStringFieldSchema(OrderFields.RECEIVER_LOCKER_NAME),
  [OrderFields.RECEIVER_STREET]: addMaxStreetLength(getNullableStringFieldSchema(OrderFields.RECEIVER_STREET)),
  [OrderFields.RECEIVER_HOUSE_NO]: addMaxHouseNoLength(getNullableStringFieldSchema(OrderFields.RECEIVER_HOUSE_NO)),
  [OrderFields.RECEIVER_FLAT_NO]: addMaxFlatNoLength(getNullableStringFieldSchema(OrderFields.RECEIVER_FLAT_NO)),
  [OrderFields.RECEIVER_CITY]: addMaxCityLength(getNullableStringFieldSchema(OrderFields.RECEIVER_CITY)),
  [OrderFields.RECEIVER_ZIP_CODE]: addMaxZipCodeLength(getNullableStringFieldSchema(OrderFields.RECEIVER_ZIP_CODE)),
  [OrderFields.RECEIVER_COMMENTS]: addMaxCommentLength(yup.string().nullable().optional()),
  [OrderFields.RECEIVER_ADDRESS_ID]: yup.string().nullable().optional(),
  [OrderFields.INVOICE_TYPE]: yup.string().oneOf(Object.values(InvoiceType)).optional(),

  [OrderFields.INVOICE_NAME]: addMaxLengthLong(getNullableStringFieldSchema(OrderFields.INVOICE_NAME)),
  [OrderFields.INVOICE_EMAIL]: addMaxLength(getNullableStringFieldSchema(OrderFields.INVOICE_EMAIL)),
  [OrderFields.INVOICE_COMPANY_NAME]: addMaxLength(getNullableStringFieldSchema(OrderFields.INVOICE_COMPANY_NAME)),
  [OrderFields.INVOICE_COMPANY_EMAIL]: addMaxLength(getNullableStringFieldSchema(OrderFields.INVOICE_COMPANY_EMAIL)),
  [OrderFields.INVOICE_REGISTRY_CODE]: addMaxLength(getNullableStringFieldSchema(OrderFields.INVOICE_REGISTRY_CODE)),
  [OrderFields.INVOICE_VAT_NUMBER]: addMaxLength(getNullableStringFieldSchema(OrderFields.INVOICE_VAT_NUMBER)),
  [OrderFields.INVOICE_REGISTRATION_ADDRESS]: addMaxLength(
    getNullableStringFieldSchema(OrderFields.INVOICE_REGISTRATION_ADDRESS)
  ),

  [OrderFields.TERMS_AGREEMENT]: getTermsAgreementRequiredSchema(),

  [OrderFields.PAYMENT_METHOD]: getNullableStringFieldSchema(OrderFields.PAYMENT_METHOD),

  [OrderFields.PRICE]: yup.number().optional(),
  [OrderFields.ORIGINAL_PRICE]: yup.number().optional(),
});

export type TOrderFormData = Asserts<typeof orderFormSchema>;

const validateOrderFormData = (response: any): TOrderFormData => orderFormSchema.validateSync(response);

export default validateOrderFormData;

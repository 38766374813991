import { FC, useEffect } from "react";

import { useTranslation } from "next-i18next";

import { useMessage } from "@components/Message";
import { LAYOUT } from "@utils/constants";

import { checkBrowserFeatures } from "./checkBrowserFeatures";

const BrowserVersionCheckProvider: FC<{ children?: any; }> = ({ children }) => {
  const { t } = useTranslation();
  const setMessage = useMessage();

  useEffect(() => {
    const isValidBrowser = checkBrowserFeatures();

    if (!isValidBrowser) {
      setMessage({
        message: t("common-errors-browser_error_message"),
        props: {
          variant: "danger",
          sx: { mb: "0.5rem" },
          textBoxWidth: "71.25rem",
          justify: "center",
        },
        zone: LAYOUT.MESSAGE_ZONE_LAYOUT,
      });
    }
  }, [setMessage, t]);

  return <>{children}</>;
};

export default BrowserVersionCheckProvider;

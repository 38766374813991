import { isValidElement, FC, useState, useEffect } from "react";

import { Input, useMultiStyleConfig } from "@chakra-ui/react";
import { i18n, useTranslation } from "next-i18next";

import Counter from "@components/Counter";
import { withCompositeFormikField } from "@lib/withFormikField";
import ifEmojiExists from "@utils/ifEmojiExists";

import BaseInput from "./BaseInput";
import type { TTextInputProps } from "./types";

const TextInput: FC<TTextInputProps> = ({
  isFormik,
  id,
  name,
  label,
  value,
  message,
  hint,
  placeholder,
  isDisabled,
  isInvalid,
  isRequired,
  leftAddon,
  leftAddonOffset = "1rem",
  rightAddon,
  rightAddonOffset = "1rem",
  leftSpacing,
  rightSpacing,
  maxLength,
  sx,
  paddingInline,
  ...rest
}) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig("TextInput", {});
  const labelAsMessage = value ? label : undefined;
  const messageText = (isInvalid && message) || labelAsMessage;

  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [internalMessage, setInternalMessage] = useState("");

  const handleInputChange = (e) => {
    if (rest.onChange) {
      rest.onChange(e);
    }
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (value) {
      setInputValue(value.toString());
    }
  }, [value]);

  return (
    <BaseInput
      inputType="text"
      message={internalMessage || messageText}
      hint={hint}
      isDisabled={isDisabled}
      isInvalid={!!internalMessage || isInvalid}
      leftAddon={leftAddon}
      leftAddonOffset={leftAddonOffset}
      rightAddon={
        maxLength && isActive ? (
          <Counter maxLength={maxLength} currentLength={inputValue.length} isError={inputValue.length === maxLength} />
        ) : (
          rightAddon
        )
      }
      rightAddonOffset={rightAddonOffset}
      leftSpacing={leftSpacing}
      rightSpacing={rightSpacing}
      id={id || name}
      name={name}
    >
      <Input
        type="text"
        isRequired={isRequired}
        paddingInline={paddingInline}
        placeholder={placeholder || label}
        value={value}
        sx={{ ...styles.input, ...sx }}
        pt={messageText ? "1.125rem" : "0"}
        pl={isValidElement(leftAddon) ? leftAddonOffset : "1rem"}
        pr={isValidElement(rightAddon) ? rightAddonOffset : "1rem"}
        id={id || name}
        name={name}
        maxLength={maxLength}
        variant="unstyled"
        onFocus={() => setIsActive(true)}
        {...rest}
        onChange={handleInputChange}
        onBlur={(e) => {
          if (!isFormik) {
            const isEmojiExists = ifEmojiExists(e.target.value);
            setInternalMessage(isEmojiExists ? t("common-invalid_emoji_input") : "");
          }
          if (rest.onBlur) {
            rest.onBlur(e);
          }
          setIsActive(false);
        }}
      />
    </BaseInput>
  );
};

const validate = (value?: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  const isEmojiExists = ifEmojiExists(value);

  return isEmojiExists ? i18n?.t("common-invalid_emoji_input") : undefined;
};
export default withCompositeFormikField({ validate })(TextInput);

import { RecursiveCSSObject, SystemCSSProperties, SystemProps } from "@chakra-ui/react";

import type { TBaseInputProps } from "../types";

type TStyleOverrideProps = Pick<TBaseInputProps, "isInvalid" | "isDisabled" | "inputType"> & {
  leftSpacing?: SystemProps["left"];
  rightSpacing?: SystemProps["right"];
};

const getStyleOverrides = ({
  inputType,
  isInvalid,
  isDisabled,
  leftSpacing,
  rightSpacing,
}: TStyleOverrideProps): Record<string, RecursiveCSSObject<SystemCSSProperties>> => ({
  inputContainer: {
    ...(inputType === "text" && { height: "2.75em" }),
    ...(inputType === "textarea" && { height: "6.75rem", alignItems: "flex-start" }),

    ...(isInvalid && { borderColor: "red.lead", boxShadow: "error" }),
    ...(isDisabled && { bg: "grey.light" }),
  },
  leftAddon: {
    ...(leftSpacing && { left: leftSpacing as any }),
    ...(inputType === "textarea" && { my: "0.5rem" }),
  },
  rightAddon: {
    ...(rightSpacing && { right: rightSpacing as any }),
    ...(inputType === "textarea" && { my: "0.5rem" }),
  },
});

export default getStyleOverrides;

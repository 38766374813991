import { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const PageHeader: ComponentMultiStyleConfig = {
  parts: ["headerContainer", "headerText"],
  baseStyle: {
    headerContainer: {
      background: "grey.warm",
      height: responsiveStyle({ mobile: "5.25rem", desktop: "7.25rem" }),
      display: "flex",
      alignItems: "center",
      mx: responsiveStyle({ mobile: "0.5rem", desktop: "0" }),
    },
    headerText: {
      fontWeight: 400,
      fontSize: responsiveStyle({ mobile: "1.25rem", desktop: "1.875rem" }),
      color: "dpd-black",
      mx: "1rem",
    },
  },
};

export default PageHeader;

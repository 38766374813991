import createApiService, { TServiceReturns } from "@lib/create-api-service";
import { TCreateUserFeedbackRequest } from "../types";
import { API_ROUTES } from "@utils/constants";

const createFeedback = createApiService<unknown, TCreateUserFeedbackRequest>({
  method: "POST",
  url: API_ROUTES.USER_FEEDBACK,
  allowedErrStatus: [409, 422],
});

export const createUserFeedback = (
  requestData: TCreateUserFeedbackRequest
): Promise<TServiceReturns<unknown, TCreateUserFeedbackRequest>> => createFeedback({ data: requestData });

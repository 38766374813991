import { FC, isValidElement } from "react";

import { FormControl, Box, useMultiStyleConfig } from "@chakra-ui/react";

import { FormHint, FormMessage } from "@components/common";

import type { TBaseInputProps } from "./types";
import getStyleOverrides from "./utils/getStyleOverrides";

const BaseInput: FC<TBaseInputProps> = ({
  id,
  inputType,
  message,
  hint,
  isDisabled,
  isInvalid,
  leftAddon,
  leftAddonOffset,
  rightAddon,
  rightAddonOffset,
  leftSpacing,
  rightSpacing,
  children,
}) => {
  const styles = useMultiStyleConfig("TextInput", {});

  const styleOverrides = getStyleOverrides({ isInvalid, isDisabled, leftSpacing, rightSpacing, inputType });
  const labelOffset = {
    left: isValidElement(leftAddon) ? leftAddonOffset : "1rem",
    right: isValidElement(rightAddon) ? rightAddonOffset : "1rem",
  };
  return (
    <FormControl id={id} isDisabled={isDisabled} isInvalid={isInvalid}>
      <Box sx={{ ...styles.inputContainer, ...styleOverrides.inputContainer }}>
        {isValidElement(leftAddon) && <Box sx={{ ...styles.leftAddon, ...styleOverrides.leftAddon }}>{leftAddon}</Box>}
        <Box sx={styles.inputContent}>
          <FormMessage message={message} isInvalid={isInvalid} hasNoSpacing sx={{ ...styles.label, ...labelOffset }} />
          {children}
        </Box>
        {isValidElement(rightAddon) && (
          <Box sx={{ ...styles.rightAddon, ...styleOverrides.rightAddon }}>{rightAddon}</Box>
        )}
      </Box>
      <FormHint hint={hint} />
    </FormControl>
  );
};

export default BaseInput;

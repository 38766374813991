import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";
import responsiveStyle from "@lib/theme/responsiveStyle";

const ActionCard: ComponentMultiStyleConfig = {
  parts: [
    "card",
    "cardWithIcon",
    "cardVertical",
    "iconContainer",
    "iconVertical",
    "label",
    "multilineLabel",
    "subText",
  ],
  baseStyle: {
    card: {
      display: "flex",
      color: "white",
      justifyContent: responsiveStyle({ mobile: "center", desktop: "flex-start" }),
      alignItems: responsiveStyle({ mobile: "center", desktop: "flex-end" }),
      px: responsiveStyle({ mobile: "1rem", desktop: "1rem", desktopWide: "1.2rem" }),
      py: responsiveStyle({ mobile: "0", desktop: "0.7rem", desktopWide: "1.0rem" }),
      fontSize: responsiveStyle({ mobile: "0.875rem", desktop: "2rem" }),
      textAlign: responsiveStyle({ mobile: "center", desktop: "left" }),
      _hover: {
        textDecoration: "underline",
      },
      ...focusable,
    },
    cardWithIcon: {
      flexDirection: responsiveStyle({ mobile: "row", desktop: "column" }),
      justifyContent: "center",
      alignItems: "center",
      fontSize: responsiveStyle({ mobile: "0.875rem", desktop: "1.5rem" }),
    },
    cardVertical: {
      flexDirection: "column",
      justifyContent: responsiveStyle({ mobile: "flex-start", desktop: "center" }),
      py: responsiveStyle({ mobile: "1.75rem", desktop: "0.7rem", desktopWide: "1.0rem" }),
      textAlign: "center",
    },
    iconContainer: {
      marginBottom: responsiveStyle({ mobile: "0", desktop: "0.5rem" }),
      marginRight: responsiveStyle({ mobile: "0.5rem", desktop: "0" }),
      height: "1.875rem",
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
    },
    iconVertical: {
      marginBottom: responsiveStyle({ mobile: "0.5rem", desktop: "1rem" }),
      marginRight: 0,
    },
    label: {
      fontWeight: 300,
      lineHeight: 1.2,
    },
    multilineLabel: {
      "&:first-line": {
        wordSpacing: responsiveStyle({ mobile: "initial", desktop: "9999rem" }),
      },
    },
    subText: {
      fontSize: "0.875rem",
      minWidth: "30%",
      textAlign: "right",
    },
  },
  variants: {
    primary: {
      card: {
        background: "red.lead",
      },
    },
    secondary: {
      card: {
        background: "grey.light",
        color: "black",
      },
    },
    dark: {
      card: {
        background: "grey.mid",
      },
    },
  },
};
export default ActionCard;

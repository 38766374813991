import { useState, useEffect, useCallback } from "react";

type TUpdateValueReturn<T> = [T, (value: T) => void];

interface IUpdateValueHook {
  <T>(defaultValue: T): TUpdateValueReturn<T>;
  <T>(defaultValue?: T): TUpdateValueReturn<T | undefined>;
}

const useUpdateValue: IUpdateValueHook = <T extends any>(defaultValue: T): TUpdateValueReturn<T> => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (typeof defaultValue !== "undefined") {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const updateValue = useCallback((val: T) => {
    setValue(val);
  }, []);

  return [value, updateValue];
};

export default useUpdateValue;

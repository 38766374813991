import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Expand: ComponentMultiStyleConfig = {
  parts: ["expandItem", "expandButton", "expandHeader", "expandPanel"],
  baseStyle: {
    expandItem: { border: "none", width: "100%" },
    expandButton: {
      paddingY: "0.625rem",
      width: "100%",
      textAlign: "left",
      _hover: { textDecoration: "underline" },
    },
    expandHeader: {
      padding: 0,
      _hover: { backgroundColor: "inherit" },
    },
    expandPanel: { padding: 0 },
  },
  variants: {
    grey: { expandItem: { backgroundColor: "grey.light" } },
    transparent: {
      expandItem: { backgroundColor: "transparent" },
    },
  },
};

export default Expand;

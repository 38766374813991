import { useEffect } from "react";

import { FormikErrors, useFormikContext } from "formik";

/**
 * Note! useFormErrors can only be used within formik component.
 * @param errors error fields object returned from submit response.
 */
const useFormErrors = <T = Record<string, any>>(errors?: FormikErrors<T>): void => {
  const { setErrors } = useFormikContext();

  useEffect(() => {
    if (errors) {
      setErrors(errors);
    }
  }, [errors, setErrors]);
};

export default useFormErrors;

import { useCallback, useState } from "react";

import Cookies from "js-cookie";

import { reportError } from "@utils/errorReporting";
import PersistentBrowserStorage from "@utils/PersistentBrowserStorage";

import { AUTH_COOKIE_EXPIRATION, AUTH_COOKIE_NAME, LS_ORDER_STORAGE } from "../constants";
import type { TAuthData, TUseAuthDataReturn } from "../types";

const getAuthDataFromCookie = (): TAuthData | null => {
  const dataStr = Cookies.get(AUTH_COOKIE_NAME);
  if (!dataStr) {
    return null;
  }
  try {
    return JSON.parse(dataStr);
  } catch (err) {
    // Corrupted cookie
    return null;
  }
};

const updateAuthDataCookie = (authData: TAuthData) => {
  try {
    Cookies.set(AUTH_COOKIE_NAME, JSON.stringify(authData), {
      expires: new Date(Date.now() + AUTH_COOKIE_EXPIRATION),
      sameSite: "strict",
    });
  } catch (err) {
    // Something is wrong with new authData.
    reportError(err, { module: "auth" });
  }
};

const clearAuthDataCookie = () => {
  try {
    Cookies.remove(AUTH_COOKIE_NAME);
  } catch (err) {
    // Failed to remove cookie?
    reportError(err, { module: "auth" });
  }
};

const clearUserDataStorage = () => {
  PersistentBrowserStorage.removeData(LS_ORDER_STORAGE);
};

const useAuthData = (): TUseAuthDataReturn => {
  const [authData, setAuthData] = useState<TAuthData | null>(getAuthDataFromCookie());

  const updateAuthData = useCallback((newAuthData: TAuthData | null) => {
    setAuthData(newAuthData);

    if (!newAuthData) {
      clearAuthDataCookie();
      clearUserDataStorage();
      return;
    }

    updateAuthDataCookie(newAuthData);
  }, []);

  return [authData, updateAuthData];
};
export default useAuthData;

import { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const Login: ComponentMultiStyleConfig = {
  parts: ["title", "linkContainer"],
  baseStyle: {
    title: {
      alignSelf: "center",
      textAlign: "center",
      px: "1rem",
      mt: "1.2rem",
      mb: "0.4rem",
    },
    linkContainer: {
      fontSize: responsiveStyle({ mobile: "0.875rem", desktop: "1rem" }),
    },
    facebookLogin: {
      my: "2rem",
      ".fb-login-button": {
        background: "facebook",
        color: "white",
        padding: "0.5rem",
        borderRadius: "2px",
        display: "flex",
        alignItems: "center",
        svg: {
          mr: "0.5rem",
        },
      },
    },
  },
};

export default Login;

import { useCallback } from "react";

import { useTranslation } from "next-i18next";

import Icon from "@components/Icon";
import { useMessage } from "@components/Message";
import { MESSAGE_ZONE_USER_FEEDBACK_FORM } from "../utils/constants";

export const useUserFeedbackFormErrorMessage = (): ((message?: string | undefined) => void) => {
  const { t } = useTranslation();
  const setMessage = useMessage();
  const handleError = useCallback(
    (message?: string) => {
      setMessage({
        message: message || t("user-feedback-message-failed"),
        props: { variant: "error", icon: <Icon icon="warning" size="1.75rem" />, isAnimated: false, canClose: true },
        zone: MESSAGE_ZONE_USER_FEEDBACK_FORM,
      });
    },
    [setMessage, t]
  );
  return handleError;
};

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const Footer: ComponentMultiStyleConfig = {
  parts: ["section", "container", "text", "wrapper", "imageContainer"],
  baseStyle: {
    section: { backgroundColor: "grey.warm", width: "full" },
    container: {
      justifyContent: "space-between",
      py: responsiveStyle({ mobile: "1rem", desktop: "2rem" }),
      alignItems: responsiveStyle({ mobile: "start", desktop: "center" }),
    },
    text: { fontSize: responsiveStyle({ mobile: "0.875rem", desktop: "1rem" }), lineHeight: "200%" },
    wrapper: { paddingX: responsiveStyle({ mobile: "1.5rem", desktop: "0.5rem" }) },
    imageContainer: {
      paddingY: responsiveStyle({ mobile: "0.5rem", desktop: "0" }),
    },
  },
};
export default Footer;

import { AxiosRequestConfig } from "axios";

import { HEADERS } from "@utils/constants";

import type { TAuthData } from "../types";

const addTokenToRequest = (requestConfig: AxiosRequestConfig | undefined, { token }: TAuthData): AxiosRequestConfig => {
  const modifiedConfig = { ...requestConfig, headers: { ...requestConfig?.headers } };
  modifiedConfig.headers[HEADERS.AUTHORIZATION] = `Bearer ${token}`;
  return modifiedConfig;
};
export default addTokenToRequest;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const Card: ComponentMultiStyleConfig = {
  parts: ["container", "group"],
  baseStyle: {
    container: {
      background: "grey.accents-4",
      px: "0.7rem",
    },
    group: {
      display: "grid",
      gridTemplateColumns: responsiveStyle({ mobile: "1fr", desktop: "repeat(auto-fill, minmax(20rem, 1fr))" }),
      justifyContent: "start",
      gap: responsiveStyle({ mobile: "0", desktop: "0.5rem" }),
    },
  },
};
export default Card;

const colors = {
  red: { lead: "#DC0032", transparent: "rgba(231, 26, 52, 0.15)", warm: "#FABD91" },
  grey: {
    warm: "#cac4be",
    mid: "#757575",
    light: "#e6e7e8",
    outline: "#ababac",
    accents: "#C4C4C4",
    "accents-2": "#D3D0D0",
    "accents-3": "#F1F1F1",
    "accents-4": "#F2F2F2",
    "accents-5": "#666666",
    "accents-6": "#808285",
  },
  green: { warm: "#339e0d", light: "#15B44B", accents: "#EBFDF1", "accents-2": "#D4F3B5" },
  yellow: { warm: "#ebc746", light: "#F2E678" },
  orange: { warm: "#ff5b00" },
  "dpd-black": "#414042",
  white: "#ffffff",
  black: "#000000",
  "overlay-black": "rgba(136, 136, 136, 0.74)",
  "overlay-white": "rgba(255, 255, 255, 0.74)",
  blue: "#E7F0FE",
  shadow: "rgba(0, 0, 0, 0.5)",
  facebook: "#4267b2",
};

export default colors;

import getEnv from "@utils/getEnv";
import getMultipleValuesFromString from "@utils/getMultipleValuesFromString";

const getBaseApiUrl = (host: string): string | null => {
  const ltDomainNames = getEnv("NEXT_PUBLIC_DOMAIN_LT");
  const lvDomainNames = getEnv("NEXT_PUBLIC_DOMAIN_LV");
  const eeDomainNames = getEnv("NEXT_PUBLIC_DOMAIN_EE");

  if (!ltDomainNames || !lvDomainNames || !eeDomainNames) {
    throw new Error("Invalid NEXT_PUBLIC_DOMAIN_*");
  }

  const ltBaseApiUrl = getEnv("NEXT_PUBLIC_BASE_API_URL_LT");
  const lvBaseApiUrl = getEnv("NEXT_PUBLIC_BASE_API_URL_LV");
  const eeBaseApiUrl = getEnv("NEXT_PUBLIC_BASE_API_URL_EE");

  if (!ltBaseApiUrl || !lvBaseApiUrl || !eeBaseApiUrl) {
    throw new Error("Invalid NEXT_PUBLIC_BASE_API_URL_*");
  }

  const domainsToApiUrls = [
    [ltDomainNames, ltBaseApiUrl],
    [lvDomainNames, lvBaseApiUrl],
    [eeDomainNames, eeBaseApiUrl],
  ];

  return domainsToApiUrls.reduce<string | null>((selectedBaseApiUrl, [domainsStr, baseApiUrl]) => {
    const domains = getMultipleValuesFromString(domainsStr);
    if (domains.includes(host)) {
      return baseApiUrl;
    }
    return selectedBaseApiUrl;
  }, null);
};
export default getBaseApiUrl;

import React, { FC } from "react";

import { Box, Text } from "@chakra-ui/react";

type TCounterProps = {
  maxLength: number;
  currentLength: number;
  isError: boolean;
};

const Counter: FC<TCounterProps> = ({ maxLength, currentLength, isError }) => (
  <Box>
    <Text color={isError ? "red.500" : "gray.500"} ml="0.5rem" fontSize="12px">
      {maxLength - currentLength}
    </Text>
  </Box>
);

export default Counter;

import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const TextInput: ComponentMultiStyleConfig = {
  parts: ["submittedViewWrapper", "submittedViewValue", "floatingButton", "input"],
  baseStyle: {
    submittedViewWrapper: {
      height: "2.75rem",
      border: "1px",
      borderColor: "green.light",
      px: "0.75rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      overflow: "hidden",
    },
    submittedViewValue: {
      flex: 1,
      mx: "0.5rem",
    },
    floatingButton: { position: "absolute", top: 0, right: 0, bottom: 0 },
    input: {
      paddingRight: "6.25rem",
    },
  },
};
export default TextInput;

import { FC, useCallback, useState } from "react";

import { i18n, useTranslation } from "next-i18next";

import TextInput from "@components/TextInput";
import useUpdateValue from "@hooks/useUpdateValue";
import { withCompositeFormikField } from "@lib/withFormikField";

import { TEmailInputProps } from "./types";
import { isEmailValid } from "./utils";

const EmailInput: FC<TEmailInputProps> = ({
  isFormik,
  defaultValue,
  onChange,
  onBlur,
  message,
  isInvalid,
  ...rest
}) => {
  const { t } = useTranslation();

  const [innerValue, updateValue] = useUpdateValue(defaultValue || "");
  const [innerErrorMessage, setInnerErrorMessage] = useState("");

  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(evt);
      const { value } = evt.target;
      updateValue(value);
    },
    [onChange, updateValue]
  );

  const handleInputBlur = useCallback(
    (evt) => {
      onBlur?.(evt);

      if (!isFormik) {
        setInnerErrorMessage(!isEmailValid(innerValue || "") ? t("common-email_input-invalid_format") : "");
      }
    },
    [innerValue, isFormik, onBlur, t]
  );

  return (
    <TextInput
      isFormik={false}
      type="email"
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      value={innerValue}
      isInvalid={!!innerErrorMessage || isInvalid}
      message={innerErrorMessage || message}
      {...rest}
    />
  );
};

const validate = (email?: string): string | undefined => {
  if (!email) {
    return undefined;
  }

  const isValid = isEmailValid(email);
  return isValid ? undefined : i18n?.t("common-email_input-invalid_format");
};

export default withCompositeFormikField({ validate })(EmailInput);

import { useContext } from "react";

import { MessageActionContext } from "../context";
import { TSetMessageParams } from "../types";

const useMessage = (): ((message: TSetMessageParams) => void) => {
  const setMessage = useContext(MessageActionContext);
  return setMessage;
};
export default useMessage;

import React, { useState, FC, Dispatch, SetStateAction, useEffect } from "react";
import html2canvas from "html2canvas";
import { i18n } from "next-i18next";

type TScreenshotTool = {
    isInScreenshotMode: boolean;
    toggleScreenshotMode: Dispatch<SetStateAction<boolean>>;
    onCapture: (screenshot: string) => void;
    cursorPosition: { x: number; y: number } | null;
};

type TSelectionBox = {
    startX: number;
    startY: number;
    width: number;
    height: number;
    left: number;
    top: number;
    endX: number;
    endY: number;
};

const ScreenshotTool: FC<TScreenshotTool> = ({ isInScreenshotMode, toggleScreenshotMode, onCapture, cursorPosition }) => {
    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [selectionBox, setSelectionBox] = useState<TSelectionBox | null>(null);
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

    const startSelection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        const startX = e.clientX;
        const startY = e.clientY;

        setIsSelecting(true);
        setTooltipVisible(true);
        setTooltipPosition({ top: startY + 10, left: startX + 10 }); // Position tooltip at start

        setSelectionBox({
            startX,
            startY,
            left: startX,
            top: startY,
            width: 0,
            height: 0,
            endX: 0,
            endY: 0,
        });
    };

    const updateSelection = (e: MouseEvent): void => {
        if (!isSelecting || !selectionBox) return;

        const endX = e.clientX;
        const endY = e.clientY;

        setSelectionBox({
            ...selectionBox,
            width: Math.abs(endX - selectionBox.startX),
            height: Math.abs(endY - selectionBox.startY),
            left: Math.min(selectionBox.startX, endX),
            top: Math.min(selectionBox.startY, endY),
            endX,
            endY,
        });

        // Update tooltip position to follow the cursor
        setTooltipPosition({ top: endY + 10, left: endX + 10 });
    };

    const endSelection = async (): Promise<void> => {
        setIsSelecting(false);
        setTooltipVisible(false); // Hide tooltip after selection ends

        if (!selectionBox) return;

        const overlayElement = document.querySelector('.screenshot-overlay') as HTMLElement | null;;
        if (overlayElement) overlayElement.style.display = 'none';

        const canvas = await html2canvas(document.body, {
            x: Math.min(selectionBox.startX, selectionBox.endX) + window.scrollX + 2,
            y: Math.min(selectionBox.startY, selectionBox.endY) + window.scrollY + 2,
            width: selectionBox.width - 4,
            height: selectionBox.height - 4,
            logging: false,
            backgroundColor: "#ffffff",
        });

        if (overlayElement?.style) overlayElement.style.display = 'block';

        const base64Image = canvas.toDataURL("image/png");
        onCapture(base64Image);

        toggleScreenshotMode(false);
        setSelectionBox(null);
    };

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            setTooltipPosition({ top: e.clientY, left: e.clientX })
            updateSelection(e);
        };
        const handleMouseUp = () => endSelection();
        if (isInScreenshotMode) {
            window.addEventListener("mousemove", handleMouseMove);
        }
        if (isSelecting) {
            window.addEventListener("mouseup", handleMouseUp);
        } else {
            setTooltipVisible(false);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isSelecting, selectionBox, isInScreenshotMode]);

    useEffect(() => {
        if (isInScreenshotMode) {
            setTooltipVisible(true);
            if (cursorPosition !== null) {
                setTooltipPosition({top: cursorPosition.y, left: cursorPosition.x})
            }
        }
    }, [isInScreenshotMode, cursorPosition]);

    if (!isInScreenshotMode) {
        return null;
    }

    const selectAnAreaTranslation = i18n?.t("user-feedback-message-select-an-area");

    return (
        <div
            className={'screenshot-overlay'}
            onMouseDown={startSelection}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 999,
                cursor: "crosshair",
            }}
        >
            {/* Tooltip without background */}
            {tooltipVisible && selectAnAreaTranslation && selectAnAreaTranslation !== 'user-feedback-message-select-an-area' && (
                <div
                    style={{
                        position: "absolute",
                        top: tooltipPosition.top,
                        left: tooltipPosition.left,
                        color: "black",
                        padding: "5px 10px",
                        pointerEvents: "none",
                        whiteSpace: "nowrap",
                        zIndex: 1000,
                        fontSize: "14px",
                        background: "none",
                        userSelect: "none",
                    }}
                >
                    { selectAnAreaTranslation }
                </div>
            )}

            {!isSelecting && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(255, 255, 255, 0.7)", // Dark background
                        pointerEvents: "none",
                    }}
                />
            )}

            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    pointerEvents: "none",
                    ...(selectionBox && {
                        clipPath: `polygon(
              0 0,
              100% 0,
              100% 100%,
              0 100%,
              0 ${selectionBox.top}px,
              ${selectionBox.left}px ${selectionBox.top}px,
              ${selectionBox.left}px ${selectionBox.top + selectionBox.height}px,
              ${selectionBox.left + selectionBox.width}px ${selectionBox.top + selectionBox.height}px,
              ${selectionBox.left + selectionBox.width}px ${selectionBox.top}px,
              ${selectionBox.left}px ${selectionBox.top}px,
              0 ${selectionBox.top}px
            )`,
                    }),
                }}
            />

            {selectionBox && (
                <>
                    <div
                        className="selection-box"
                        style={{
                            left: selectionBox.left,
                            top: selectionBox.top,
                            width: `${selectionBox.width}px`,
                            height: `${selectionBox.height}px`,
                            position: "absolute",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                            border: "2px solid red",
                            boxSizing: "border-box",
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default ScreenshotTool;

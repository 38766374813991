import { FC } from "react";

import { Text, useMultiStyleConfig, TextProps } from "@chakra-ui/react";
/**
 *
 * @param type Returns text of different sizes:
 *
 * 'body' - font-size: 16px;
 *
 * 'small' - font-size: 14px;
 *
 * 'tiny' - font-size: 12px;
 *
 * 'sectionHeading' - font-size: 30px;
 *
 */
const Typography: FC<{ type?: "body" | "big" | "small" | "tiny" | "sectionHeading" | "paragraphHeading" } & TextProps> =
  ({ type = "body", children, sx, ...rest }) => {
    const styles = useMultiStyleConfig("Common", {});
    return (
      <Text sx={{ ...styles[`${type}Text`], ...sx }} {...rest}>
        {children}
      </Text>
    );
  };

export default Typography;

import React, { useState } from "react";
import { Radio, HStack, Box } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { StarIcon } from "@chakra-ui/icons";

interface StarRatingProps {
  name: string;
  count: number;
}

const StarRating: React.FC<StarRatingProps> = ({ name, count }) => {
  const [hover, setHover] = useState<number | null>(null);

  const { setFieldValue, validateField } = useFormikContext();
  const [field] = useField(name);

  return (
    <HStack gap={0}>
      {[...Array(count)].map((_, index) => {
        const ratingValue = index + 1;

        return (
          <Box
            as="label"
            key={index}
            color={ratingValue <= (hover || field.value || 0) ? "#ffc107" : "#e4e5e9"}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(null)}
            onClick={() => {
              setFieldValue(name, ratingValue, false);
              validateField(name);
            }}
            pr={2}
          >
            <Radio name={name} value={ratingValue.toString()} display="none" />
            <StarIcon cursor="pointer" fontSize="xl" transition="color 200ms" />
          </Box>
        );
      })}
    </HStack>
  );
};

export default StarRating;

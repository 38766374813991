import { ComponentMultiStyleConfig } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

const Faq: ComponentMultiStyleConfig = {
  parts: ["icon", "question", "questionText", "expandedQuestionText", "answer", "link"],
  baseStyle: {
    icon: {
      background: "red.lead",
      color: "white",
      width: "1.625rem",
      height: "1.625rem",
      flexShrink: 0,
      borderRadius: "100%",
      marginRight: "1.25rem",
    },

    question: {
      textAlign: "left",
      _hover: { textDecoration: "underline", color: "red.lead" },
    },

    questionText: {
      fontSize: "1.125rem",
      fontWeight: "light",
      lineHeight: "1.2",
    },
    expandedQuestionText: { fontSize: "1.125rem", lineHeight: "1.2" },

    answer: {
      pt: "0.6875rem",
      ml: "2.875rem",
      fontSize: responsiveStyle({ desktop: "0.875rem", mobile: "1rem" }),
      lineHeight: "150%",
      fontWeight: "light",
      "& a": {
        textDecoration: "underline",
      },
      "& a:hover": {
        color: "red.lead",
      },
    },

    link: { fontSize: "1.125rem" },
  },
};

export default Faq;

import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import getEnv from "@utils/getEnv";
import { tryForAWhile } from "@utils/tryForAWhile";

type TRecaptchaHook = (action?: string) => () => Promise<string | null>;

// Test token used when recaptcha key is not provided (test, staging environments)
const TEST_RECAPTCHA_TOKEN = "recaptcha-token";

/**
 *
 * @param action the action name for this request (important to verify).
 * Read more: https://developers.google.com/recaptcha/docs/v3#actions
 * @returns a function that invokes recaptcha v3 and returns a token promise.
 *
 */
const useRecaptcha: TRecaptchaHook = (action?: string) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleRecaptchaCheck = useCallback(async (): Promise<string | null> => {
    try {
      if (
        !getEnv("NEXT_PUBLIC_RECAPTCHA_KEY_LT") &&
        !getEnv("NEXT_PUBLIC_RECAPTCHA_KEY_LV") &&
        !getEnv("NEXT_PUBLIC_RECAPTCHA_KEY_EE")
      ) {
        return TEST_RECAPTCHA_TOKEN;
      }
      const token = await tryForAWhile(() => executeRecaptcha?.(action), 3000);
      return token;
    } catch (_) {
      return null;
    }
  }, [executeRecaptcha, action]);

  return handleRecaptchaCheck;
};

export default useRecaptcha;

import { AxiosRequestConfig } from "axios";

import { HEADERS } from "@utils/constants";

const addRetryToRequest = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => ({
  ...requestConfig,
  headers: {
    ...requestConfig.headers,
    [HEADERS.RETRY]: "true",
  },
});
export default addRetryToRequest;
